import { Button, Tooltip, Typography } from '@mui/material';
import { useCancelWaitingStatus, useDeleteConsultation, useQueryConsultations } from 'api';
import DataGridCustom from 'components/dataGrid/DataGridCustom';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Consultation } from 'models/consultation.model';
import { WAITING_ROOM_COLOR, WaitingRoom, WaitingRoomStatus } from 'models/waitingRoom.model';
import { useContext, useEffect, useState } from 'react';
import EditIcon from '@mui/icons-material/ModeEdit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import { ModalContext } from 'components/ModalProvider';
import { NotificationContext } from 'components/NotificationProvider';
import ConsultationModal from 'components/modals/ConsultationModal';
import useDynamicTranslation from 'hooks/useDynamicTranslation';

const ConsultationsList = ({
  waitingRoom,
  isDisabled,
}: {
  waitingRoom: WaitingRoom;
  isDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const dynamicTranslation = useDynamicTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { openModal } = useContext(ModalContext);
  const [consultationsList, setConsultationsList] = useState<Consultation[]>([]);
  const [isConsultationsModalOpen, setIsConsultationsModalOpen] = useState(false);
  const [consultationId, setConsultationsId] = useState('');
  const { isLoading, data } = useQueryConsultations(waitingRoom.id);
  const { mutateAsync: deleteConsultation } = useDeleteConsultation();
  const { mutateAsync: cancelStatus } = useCancelWaitingStatus();

  const columns: GridColDef[] = [
    {
      field: 'startDate',
      headerName: t('waitingroom.historic.headerName.time'),
      sortable: false,
      width: 160,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Consultation, GridTreeNodeWithRender>,
      ) => (
        <Tooltip title={params.value ? new Date(params.row.startDate).toLocaleString() : ''} arrow>
          <Typography sx={{ maxWidth: '100%' }}>
            {params.value
              ? new Date(params.row.startDate).toLocaleTimeString([], { timeStyle: 'short' })
              : ''}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: t('waitingroom.historic.headerName.status'),
      sortable: false,
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Consultation, GridTreeNodeWithRender>,
      ) => {
        return (
          <>
            {params.row.id === consultationsList[0]?.id ? (
              <CircleIcon
                sx={{
                  color: WAITING_ROOM_COLOR[params.row.status as WaitingRoomStatus],
                  width: '8px',
                  height: '8px',
                  marginRight: '8px',
                }}
              />
            ) : (
              <></>
            )}
            <Tooltip
              title={
                // lower first letter to ensure it matches the translation key format (camel case)
                dynamicTranslation(
                  `waitingroom.historic.status.${params.row.status
                    .charAt(0)
                    .toLowerCase()}${params.row.status.slice(1)}`,
                  {
                    remark: params.row.remark,
                  },
                ) ?? ''
              }
              arrow
            >
              <Typography sx={{ maxWidth: '100%' }}>
                {
                  // lower first letter to ensure it matches the translation key format (camel case)
                  dynamicTranslation(
                    `waitingroom.historic.status.${params.row.status
                      .charAt(0)
                      .toLowerCase()}${params.row.status.slice(1)}`,
                    {
                      remark: params.row.remark,
                    },
                  ) ?? ''
                }
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: 'fullnameUser',
      headerName: t('waitingroom.historic.headerName.practitioner'),
      flex: 1,
      sortable: false,
    },
  ];

  if (!isDisabled) {
    columns.push({
      field: 'actions',
      headerName: t('waitingroom.historic.headerName.actions'),
      width: 230,
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Consultation, GridTreeNodeWithRender>,
      ) =>
        params.row.status !== WaitingRoomStatus.Waiting ? (
          <>
            <Tooltip title={t('action.modify')} arrow>
              <Button
                startIcon={<EditIcon />}
                onClick={() => {
                  setConsultationsId(params.row.id);
                  setIsConsultationsModalOpen(true);
                }}
                sx={{
                  color: 'darkText.main',
                  textDecoration: 'none',
                  marginRight: '16px',
                  padding: '5px 16px',
                }}
              >
                {t('action.modify')}
              </Button>
            </Tooltip>
            <Tooltip title={t('action.delete')} arrow>
              <Button
                startIcon={<DeleteForeverIcon />}
                onClick={() => {
                  if (params.row.id) {
                    const consultationId = params.row.id;
                    openModal({
                      title: t('modals.deleteConsultation.title'),
                      message: t('modals.deleteConsultation.message'),
                      validButton: {
                        label: t('action.delete'),
                        icon: <DeleteForeverIcon />,
                        onClick: () => {
                          deleteConsultation(consultationId);
                          sendNotification(t('notifications.consultation.delete'), 'success');
                        },
                      },
                      cancelButton: {
                        label: t('action.cancel'),
                      },
                    });
                  }
                }}
                sx={{ color: 'darkText.main', textDecoration: 'none', padding: '5px 16px' }}
              >
                {t('action.delete')}
              </Button>
            </Tooltip>
          </>
        ) : (
          params.row.id === consultationsList[0]?.id && (
            <Tooltip title={t('action.delete')} arrow>
              <Button
                startIcon={<DeleteForeverIcon />}
                onClick={() => {
                  const consultationId = params.row.id;
                  openModal({
                    title: t('modals.deleteConsultation.title'),
                    message: t('modals.deleteConsultation.message'),
                    validButton: {
                      label: t('action.delete'),
                      icon: <DeleteForeverIcon />,
                      onClick: async () => {
                        try {
                          await cancelStatus(consultationId);
                          sendNotification(t('notifications.consultation.delete'), 'success');
                        } catch (e) {
                          const message = (e as Error).message;
                          sendNotification(
                            dynamicTranslation(`error.${message}`) ?? message,
                            'error',
                          );
                        }
                      },
                    },
                    cancelButton: {
                      label: t('action.cancel'),
                    },
                  });
                }}
                sx={{
                  color: 'darkText.main',
                  textDecoration: 'none',
                  padding: '5px 16px',
                  marginLeft: '106px',
                }}
              >
                {t('action.delete')}
              </Button>
            </Tooltip>
          )
        ),
    });
  }

  useEffect(() => {
    if (data) {
      const consultations: Consultation[] = [];

      if (
        [
          WaitingRoomStatus.Waiting,
          WaitingRoomStatus.Refused,
          WaitingRoomStatus.EndConsultation,
        ].includes(waitingRoom.status)
      ) {
        consultations.push({
          id: waitingRoom.id,
          startDate: waitingRoom.updatedDate,
          endDate: new Date(),
          status: waitingRoom.status,
          remark: waitingRoom.remark,
          fullnameUser: '',
        });
      }

      if (data.length > 0) {
        consultations.push(data[0]);
        for (let index = 1; index < data.length; index++) {
          if (data[index].endDate !== data[index - 1].startDate) {
            consultations.push({
              id: index.toString(),
              startDate: data[index].endDate,
              endDate: data[index - 1].startDate,
              status: WaitingRoomStatus.Waiting,
              remark: '',
              fullnameUser: '',
            });
          }
          consultations.push(data[index]);
        }
        consultations.push({
          id: '',
          startDate: waitingRoom.createdDate,
          endDate: data[data.length - 1].startDate,
          status: WaitingRoomStatus.Waiting,
          remark: '',
          fullnameUser: '',
        });
      }

      setConsultationsList(consultations);
    }
  }, [setConsultationsList, data, waitingRoom]);

  return (
    <>
      <DataGridCustom
        columns={columns}
        data={consultationsList}
        isLoading={isLoading}
        isPaginated={false}
        state={{
          page: 0,
          pageSize: 0,
        }}
        getRowId={(row) => row.id}
        hideFooter={true}
        sx={{
          'minHeight': '200px',
          '& .custom-data-grid-row': {
            backgroundColor: 'background.paper',
            borderBottom: 'none',
          },
          '& .custom-data-grid-row:hover': {
            backgroundColor: 'background.default',
          },
          '& .MuiDataGrid-virtualScroller': {
            overflow: 'auto',
          },
        }}
      />
      <ConsultationModal
        id={consultationId}
        isEdition={true}
        isOpen={isConsultationsModalOpen}
        onClose={() => setIsConsultationsModalOpen(false)}
      />
    </>
  );
};

export default ConsultationsList;
