import { Box, Modal, TextField, Typography } from '@mui/material';
import StyledButton from 'components/StyledButton';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';

type StatusModalProps = {
  isOpen: boolean;
  title: string;
  message: string;
  label: string;
  onValid: () => void;
  onCancel: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const StatusModal = ({
  isOpen,
  title,
  message,
  label,
  onValid,
  onCancel,
  onChange,
}: StatusModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal open={isOpen}>
      <Box
        sx={{
          padding: '16px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          overflowX: 'hidden',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          color: 'primary.main',
          width: '40vw',
          minWidth: '420px',
        }}
      >
        <Typography
          component="h2"
          sx={{
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '32px',
            fontSize: '22px',
            color: 'gray1.main',
          }}
        >
          {title}
        </Typography>

        <Typography sx={{ color: 'gray1.main' }}>{message}</Typography>

        <TextField
          label={label}
          type="text"
          sx={{ width: '100%', marginTop: '16px', marginBottom: '16px' }}
          onChange={onChange}
        />

        <Box
          component="span"
          sx={{
            m: 1,
            justifyContent: 'center',
            display: 'flex',
            gap: '50px',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <StyledButton variant="outlined" startIcon={<ArrowBackIcon />} onClick={onCancel}>
            {t('action.back')}
          </StyledButton>
          <StyledButton
            onClick={onValid}
            startIcon={<DoneIcon />}
            type="submit"
            variant="contained"
          >
            {t('action.validate')}
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default StatusModal;
