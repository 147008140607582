import { Box } from '@mui/material';
import StyledButton from 'components/StyledButton';
import SearchBar from 'components/shared/SearchBar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserTable from 'components/administrationPanel/users/UserTable';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import UserModal from 'components/administrationPanel/users/UserModal';
import AdministrationNavigation from '../components/administrationPanel/AdministrationNavigation';

const UserPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string>('');
  const [isEdition, setIsEdition] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        p: '0 24px 32px 24px',
        m: 0,
        height: 'calc(100% - 64px)',
        width: '100vw',
      }}
    >
      <AdministrationNavigation currentPage={t('administrationPanel.benevoles')} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <SearchBar onSearch={setFilter} placeholder={t('user.search')} />

        <StyledButton
          startIcon={<PersonAddAltOutlinedIcon />}
          variant="contained"
          onClick={() => {
            setIsEdition(false);
            setIsOpen(true);
          }}
        >
          {t('user.add')}
        </StyledButton>
      </Box>

      <Box sx={{ height: 'inherit' }}>
        <UserTable
          filter={filter}
          editUser={(id: string) => {
            setId(id);
            setIsEdition(true);
            setIsOpen(true);
          }}
        />
      </Box>

      <UserModal id={id} isEdition={isEdition} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  );
};
export default UserPage;
