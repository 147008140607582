export const Roles = {
  Benevole: 'Benevole',
  CenterAdministrator: 'CenterAdministrator',
  SuperAdministrator: 'SuperAdministrator',
};

export type Role = {
  id: string;
  name: string;
};
