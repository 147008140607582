import { Page, TanstackQueryOptions } from 'models/api.model';
import { API_PATH } from './paths';
import useApi from './useApi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { User } from 'models/user.model';
import { QUERY_KEYS } from './queryKeys';

export const useQueryUsers = (
  page: number,
  size: number,
  filter: string,
  options?: TanstackQueryOptions<Page<User>>,
) => {
  const { getPage } = useApi();
  return useQuery<Page<User>, Error>(
    [QUERY_KEYS.USER.ALL, page, size, filter],
    async () => {
      return await getPage<User>(API_PATH.USERS, page, size, filter);
    },
    options,
  );
};

export const useDeleteUser = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => await sendDeleteRequest(`${API_PATH.USERS}/${id}`), {
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER.ALL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER.ONE, id] });
    },
  });
};

export const useQueryUser = (id?: string, options?: TanstackQueryOptions<User>) => {
  const { sendGetRequest } = useApi();

  return useQuery<User, Error>(
    [QUERY_KEYS.USER.ONE, id],
    async () => sendGetRequest<User>(`${API_PATH.USERS}/${id}`),
    options,
  );
};

export const useCreateUser = () => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (user: User) => await sendRequestWithBody('POST', API_PATH.USERS, user),
    {
      onSuccess: (_, user) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER.ONE, user.id] });
      },
    },
  );
};

export const useModifyUser = (id: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (user: User) => {
      await sendRequestWithBody('PUT', `${API_PATH.USERS}/${id}`, user);
    },
    {
      onSuccess: (_, user) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.USER.ONE, user.id] });
      },
    },
  );
};
