import { z } from 'zod';
import useAddressValidator from 'hooks/useAddressValidator';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useCenterValidator = () => {
  const { t } = useTranslation();
  const addressValidator = useAddressValidator();
  const centerValidator = useMemo(
    () =>
      z.object({
        id: z.string(),
        name: z
          .string()
          .min(1, { message: t('form.required') })
          .max(50, { message: t('form.maxSize', { max: 50 }) }),
        addressDto: addressValidator,
      }),
    [t],
  );
  return centerValidator;
};
export default useCenterValidator;
