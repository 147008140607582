import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_PATH } from 'api/paths';
import useApi from 'api/useApi';
import { Page, TanstackQueryOptions } from 'models/api.model';
import { Patient } from 'models/patient.model';
import { QUERY_KEYS } from './queryKeys';
import { formatISO } from 'date-fns';

export const useQueryPatient = (id: string, options?: TanstackQueryOptions<Patient>) => {
  const { sendGetRequest } = useApi();

  return useQuery<Patient, Error>(
    [QUERY_KEYS.PATIENT.ONE, id],
    async () => sendGetRequest<Patient>(`${API_PATH.PATIENTS}/${id}`),
    options,
  );
};

export const useQueryPatients = (
  page: number,
  size: number,
  filter: string,
  options?: TanstackQueryOptions<Page<Patient>>,
) => {
  const { getPage } = useApi();
  return useQuery<Page<Patient>, Error>(
    [QUERY_KEYS.PATIENT.ALL, page, size, filter],
    async () => getPage<Patient>(API_PATH.PATIENTS, page, size, filter),
    options,
  );
};

export const useCreatePatient = () => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (patient: Patient) => {
      const patientWithDateFormatted = {
        ...patient,
        birthDate: patient.birthDate ? formatISO(patient.birthDate) : null,
      };
      return sendRequestWithBody('POST', API_PATH.PATIENTS, patientWithDateFormatted);
    },
    { onSuccess: () => queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT.ALL] }) },
  );
};

export const useDeletePatient = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation((id: string) => sendDeleteRequest(`${API_PATH.PATIENTS}/${id}`), {
    onSuccess: (id: string) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT.ALL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT.ONE, id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
    },
  });
};

export const useModifyPatient = (id: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (patient: Patient) => {
      const patientWithDateFormatted = {
        ...patient,
        birthDate: patient.birthDate ? formatISO(patient.birthDate) : null,
      };
      return sendRequestWithBody('PUT', `${API_PATH.PATIENTS}/${id}`, patientWithDateFormatted);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.PATIENT.ONE, id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
      },
    },
  );
};
