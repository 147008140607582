import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Flags from 'country-flag-icons/react/3x2';
import { FlagsKey, Language, Translation } from 'models/language.model';
import { NotificationContext } from 'components/NotificationProvider';

const flagStyle: CSSProperties = {
  width: '2.4rem',
  margin: '5px',
};

const LanguagePicker = () => {
  const { t, i18n } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();

  const getI18nLanguages = useCallback(() => {
    const resources = i18n.options?.resources;
    if (resources) {
      const i18nLanguages: Language[] = Object.keys(resources)
        .map((language) => ({
          ...(resources[language] as Translation).translation?.i18n,
          code: language,
        }))
        .filter((language) => Object.prototype.hasOwnProperty.call(Flags, language.flag));
      setLanguages(i18nLanguages);
    }
  }, [i18n.options?.resources]);

  // Retrieve the fallback language in i18n configuration
  const getDefaultLanguage: () => Language | undefined = useCallback(() => {
    if (!i18n.options.fallbackLng) {
      return;
    }
    return languages.find((language) => language.code === i18n.options.fallbackLng?.toString());
  }, [languages, i18n.options.fallbackLng]);

  // Retrieve the current language in i18n configuration
  const getCurrentLanguage: () => Language | undefined = useCallback(
    () => languages.find((language) => language.code === i18n.language) ?? getDefaultLanguage(),
    [languages, i18n.language, getDefaultLanguage],
  );

  const handleLanguageChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const language =
        languages.find((language) => language.code === event.target.value) ?? getDefaultLanguage();
      // No need to change the current language of the component since it will be updated with useEffect
      // It allows to automatically update the component if the language in i18n changes outside of this component
      if (language) {
        i18n.changeLanguage(language.code, (err) => {
          if (err) {
            sendNotification(t('notifications.languagePicker.error'), 'error');
          }
        });
      }
    },
    [languages, i18n, getDefaultLanguage],
  );

  useEffect(() => {
    getI18nLanguages();
  }, [getI18nLanguages]);

  // Synchronize the language selected on i18n current language change
  useEffect(() => {
    setSelectedLanguage(getCurrentLanguage());
  }, [i18n.language, languages, getCurrentLanguage]);

  return (
    <Select
      sx={{
        'margin': 'auto',
        'height': '1.8rem',
        'boxShadow': 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '*:focus-visible': {
          display: 'none',
        },
        '& .MuiSelect-select': {
          zIndex: 2,
        },
        '& .MuiSvgIcon-root': {
          color: 'background.paper',
        },
        '& svg.flag': {
          borderRadius: '5px',
          borderWidth: '2px',
          borderStyle: 'solid',
          borderColor: 'background.paper',
        },
      }}
      value={selectedLanguage?.code ?? ''}
      onChange={handleLanguageChange}
      renderValue={(languageCode) => {
        const language = languages.find((language) => language.code === languageCode);
        if (!language) {
          return <></>;
        }
        const Flag = Flags[language.flag as FlagsKey];
        return <Flag className="flag" style={{ ...flagStyle, marginTop: '8px' }} />;
      }}
    >
      {languages.map((language: Language) => {
        const Flag = Flags[language.flag as FlagsKey];
        return (
          <MenuItem
            key={language.code}
            value={language.code}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Flag style={flagStyle} />
            <span style={{ lineHeight: '1.6rem' }}>{language.name}</span>
          </MenuItem>
        );
      })}
    </Select>
  );
};
export default LanguagePicker;
