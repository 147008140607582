import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useDynamicTranslation = () => {
  const { t, i18n } = useTranslation();

  const dynamicTranslation = useCallback(
    (i18nKey: string, args?: object) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: The i18n key exists since we checked for it using the exists method,
      // but typescript won't recognize it whatever I try to type it as.
      return i18n.exists(i18nKey) ? (t(i18nKey, args) as string) : undefined;
    },
    [t, i18n],
  );
  return dynamicTranslation;
};
export default useDynamicTranslation;
