import { Box, Modal, Typography } from '@mui/material';
import { useCallback } from 'react';
import { CustomModalProps } from 'models/modal.model';
import StyledButton from 'components/StyledButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';

const CustomModal = ({
  isMandatory,
  message,
  title,
  validButton,
  cancelButton,
  onClose,
}: CustomModalProps) => {
  const handleClose = useCallback(
    (reason: 'backdropClick' | 'escapeKeyDown' | 'actionCompleted') => {
      if (isMandatory && reason !== 'actionCompleted') {
        return;
      }
      if (onClose) {
        onClose();
      }
    },
    [],
  );

  const handleAction = useCallback((action?: () => void) => {
    if (action) {
      action();
    }
    handleClose('actionCompleted');
  }, []);

  return (
    <Modal open={true} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: 'auto',
          bgcolor: 'background.paper',
          borderRadius: '10px',
          boxShadow: 24,
          p: '16px 24px',
        }}
      >
        <Typography
          sx={{
            marginBottom: '8px',
            fontWeight: '700',
            color: 'gray1.main',
            textAlign: 'center',
            textTransform: 'uppercase',
            lineHeight: '140%',
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ margin: '24px 0', color: 'gray1.main' }}>{message}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '40px' }}>
          <StyledButton
            startIcon={cancelButton.icon ?? <ArrowBackIcon />}
            variant="outlined"
            onClick={() => handleAction(cancelButton.onClick)}
          >
            {cancelButton.label}
          </StyledButton>

          <StyledButton
            startIcon={validButton.icon ?? <CheckIcon />}
            variant="contained"
            onClick={() => handleAction(validButton.onClick)}
          >
            {validButton.label}
          </StyledButton>
        </Box>
      </Box>
    </Modal>
  );
};
export default CustomModal;
