import { WaitingRoomStatus } from 'models/waitingRoom.model';
import { z } from 'zod';

const consultationValidator = z.object({
  id: z.string(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  fullnameUser: z.string(),
  remark: z.string(),
  status: z.enum([
    WaitingRoomStatus.ConsultingOther,
    WaitingRoomStatus.ConsultingDoctor,
    WaitingRoomStatus.ConsultingSocialWorker,
  ]),
});

export default consultationValidator;
