import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import fr from './fr.json';

i18next.use(initReactI18next).init({
  resources: {
    fr,
    en,
  },
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});
export default i18next;
