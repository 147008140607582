import { Box, Button, SvgIcon, Typography } from '@mui/material';
import logo from 'assets/logo_MdM.png';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import LuxtrustAuthenticator from 'components/LuxtrustAuthenticator';
import { NotificationContext } from 'components/NotificationProvider';
import LanguagePicker from 'components/navbar/LanguagePicker';
import Loader from 'components/shared/Loader';
import NotificationStack from 'components/shared/NotificationStack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE } from 'router/routes';
import cover from 'assets/login.png';
import { ReactComponent as luxtrustLogo } from 'assets/luxtrust_logo.svg';

const LoginPage = () => {
  const { hash } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, isLogged, dispatchAuthentication } = useContext(AuthenticationContext);
  const { sendNotification } = useContext(NotificationContext);
  const [isLogging, setIsLogging] = useState(false);

  const handleLoginClick = useCallback(() => {
    setIsLogging(true);
  }, [setIsLogging]);

  useEffect(() => {
    const fragmentParams = new URLSearchParams(hash.slice(1));

    const token = fragmentParams.get('token');
    const refreshToken = fragmentParams.get('refresh');

    if (token && refreshToken) {
      dispatchAuthentication({ type: 'login', token, refreshToken });
    }

    if (hash === '#unauthorized') {
      sendNotification(t('notifications.login.unauthorized'), 'error');
    }

    if (hash === '#error') {
      sendNotification(t('notifications.login.error'), 'error');
    }
    navigate('');
  }, [hash]);

  if (isLoading) {
    return <Loader />;
  }

  if (isLogging) {
    return (
      <LuxtrustAuthenticator
        onError={() => {
          setIsLogging(false);
          navigate('#error');
        }}
      />
    );
  }

  if (isLogged) {
    return <Navigate to={ROUTE.PATIENTS.INDEX} />;
  }

  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        height: '100vh',
        width: '100vw',
        background: 'linear-gradient(107.45deg, #215C9A27 0%, #215C9A27 45%, rgba(1,93,171,1) 45%)',
      }}
    >
      <Box
        component="img"
        sx={{
          m: 0,
          p: 0,
          position: 'absolute',
          bottom: 10,
          left: 10,
          minWidth: '224px',
          width: '10%',
        }}
        alt={t('navbar.altLogo')}
        src={logo}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          height: '100%',
          // 15.72 is the result of 50vh*tan(17.45deg) rounded up to 0.01
          // which correspond to the offset caused by the rotation
          width: 'calc(45% + 15.72vh)',
          objectFit: 'cover',
          backgroundImage: `url(${cover})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10%',
          minWidth: '346px',
          width: '30%',
          transform: 'translate(0, -50%)',
          p: '48px',
          m: 0,
          backgroundColor: 'background.paper',
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.05)',
          borderRadius: '15px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '140%',
            color: 'gray1.main',
            textTransform: 'uppercase',
            textAlign: 'center',
          }}
        >
          {t('loginPage.title')}
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '140%',
            color: 'gray1.main',
          }}
        >
          {t('loginPage.description')}
        </Typography>

        <Button
          onClick={handleLoginClick}
          variant="contained"
          endIcon={
            <SvgIcon
              component={luxtrustLogo}
              sx={{ width: '100%', fontSize: '14px', marginTop: '1px' }}
              viewBox="0 0 700 58"
            />
          }
          sx={{
            'm': '48px auto 0 auto',
            'width': 'fit-content',
            'fontWeight': 700,
            'fontSize': '16px',
            'lineHeight': '140%',
            '& *:first-of-type svg': {
              fontSize: '10px',
            },
          }}
        >
          {t('loginPage.with')}
        </Button>
      </Box>
      <Box sx={{ position: 'absolute', top: '24px', right: '18px', p: 0, m: 0 }}>
        <LanguagePicker />
      </Box>
      <NotificationStack />
    </Box>
  );
};
export default LoginPage;
