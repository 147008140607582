import { z } from 'zod';
import useCountryValidator from 'hooks/useCountryValidator';
import { useTranslation } from 'react-i18next';

const useCityValidator = () => {
  const { t } = useTranslation();
  const countryValidator = useCountryValidator();
  const cityValidator = z.object({
    id: z.string(),
    name: z
      .string()
      .min(1, { message: t('form.required') })
      .max(60, { message: t('form.maxSize', { max: 60 }) }),
    countryDto: countryValidator,
  });
  return cityValidator;
};
export default useCityValidator;
