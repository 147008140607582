import { z } from 'zod';
import useCityValidator from 'hooks/useCityValidator';
import { useTranslation } from 'react-i18next';

const useAddressValidator = () => {
  const { t } = useTranslation();
  const cityValidator = useCityValidator();
  const addressValidator = z.object({
    id: z.string(),
    street: z
      .string()
      .min(1, { message: t('form.required') })
      .max(70, { message: t('form.maxSize', { max: 70 }) }),
    complement: z
      .string()
      .max(30, { message: t('form.maxSize', { max: 30 }) })
      .nullable(),
    zipCode: z
      .string()
      .min(1, { message: t('form.required') })
      .max(10, { message: t('form.maxSize', { max: 10 }) }),
    cityDto: cityValidator,
  });
  return addressValidator;
};
export default useAddressValidator;
