import {
  createContext,
  ReactNode,
  useState,
  useEffect,
  useReducer,
  Reducer,
  useCallback,
} from 'react';
import {
  AuthenticationAction,
  AuthenticationContextType,
  AuthenticationInformations,
  BearerToken,
} from 'models/authentication.model';
import jwt_decode from 'jwt-decode';
import useApi from 'api/useApi';
import { ErrorCode } from 'models/errorCode.model';

// Time in milliseconds between each refresh
// Currently, the token lifespan is 5 minutes and we refresh it every 4 minutes and 10 seconds
// This is not an accurate timing beacause of how it's handled (without threads)
// So it may take up to 4 minutes 30 seconds.
const AUTO_REFRESH_BEARER_TOKEN_DELAY = 250000;

const emptyLogin: AuthenticationInformations = {
  isLogged: false,
  name: '',
  roles: '',
  isOrganisationMember: false,
  token: '',
  refreshToken: '',
};

const noAuthenticationProviderError = Error(
  'You need to use getContext inside an AuthenticationContext.Provider',
);

export const AuthenticationContext = createContext<AuthenticationContextType>({
  ...emptyLogin,
  isLoading: true,
  centerId: '',
  dispatchAuthentication: function (): void {
    throw noAuthenticationProviderError;
  },
  changeCenter: () => {
    throw noAuthenticationProviderError;
  },
});

const authenticationReducer = (state: AuthenticationInformations, action: AuthenticationAction) => {
  switch (action.type) {
    case 'login': {
      const jwt = jwt_decode<BearerToken>(action.token);
      const authenticationInformations: AuthenticationInformations = {
        isLogged: true,
        name: jwt.name,
        roles: jwt.roles,
        isOrganisationMember: jwt.isOrganisationMember,
        token: action.token,
        refreshToken: action.refreshToken,
      };
      localStorage.setItem('auth', JSON.stringify(authenticationInformations));
      return authenticationInformations;
    }
    case 'logout':
      localStorage.removeItem('auth');
      return emptyLogin;
    case 'load':
      return action.parsedAuthentication;
    case 'refresh':
      if (state.isLogged) {
        action.refresh(state.refreshToken);
      }
      return state;
    default:
      throw Error(`Unknown action.`);
  }
};

const AuthenticationProvider = ({ children }: { children?: ReactNode }) => {
  const [centerId, setCenterId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [authentication, dispatchAuthentication] = useReducer<
    Reducer<AuthenticationInformations, AuthenticationAction>
  >(authenticationReducer, emptyLogin);
  const { refreshBearerToken } = useApi();

  const refresh = useCallback(
    async (refreshToken: string) => {
      try {
        const response: { token: string; refreshToken: string } = await refreshBearerToken(
          refreshToken,
        );
        return dispatchAuthentication({
          type: 'login',
          token: response.token,
          refreshToken: response.refreshToken,
        });
      } catch (e) {
        if ((e as Error).message === ErrorCode.ERROR_CONNECTION_EXPIRED) {
          return dispatchAuthentication({ type: 'logout' });
        }
      }
    },
    [dispatchAuthentication, refreshBearerToken],
  );

  const changeCenter = useCallback(
    async (id: string) => {
      setCenterId(id);
      localStorage.setItem('centerId', id);
    },
    [setCenterId],
  );

  useEffect(() => {
    // Retrieve authentication informations from local storage
    const storedAuthentication = localStorage.getItem('auth');
    const parsedAuthentication: AuthenticationInformations = storedAuthentication
      ? JSON.parse(storedAuthentication)
      : undefined;
    if (parsedAuthentication) {
      const jwt = jwt_decode<BearerToken>(parsedAuthentication.refreshToken);
      // Check if the stored authentication is still valid
      if (jwt.exp * 1000 < Date.now()) {
        dispatchAuthentication({ type: 'logout' });
      } else {
        dispatchAuthentication({ type: 'load', parsedAuthentication });
      }
    }

    const centerId = localStorage.getItem('centerId');
    if (centerId) {
      setCenterId(centerId);
    }
    setIsLoading(false);
    // Create a timer to refresh authentication periodically
    // The useEffect may fire twice in debug with Strict Mode enabled
    // Causing two refresh request at the same time
    dispatchAuthentication({
      type: 'refresh',
      refresh,
    });
    const refreshTimer = setInterval(
      () =>
        dispatchAuthentication({
          type: 'refresh',
          refresh,
        }),
      AUTO_REFRESH_BEARER_TOKEN_DELAY,
    );
    return () => clearInterval(refreshTimer);
  }, []);

  return (
    <AuthenticationContext.Provider
      value={{ ...authentication, isLoading, centerId, dispatchAuthentication, changeCenter }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
export default AuthenticationProvider;
