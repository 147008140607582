import { AuthenticationForm, AuthenticationRequest } from 'models/authentication.model';
import { t } from 'i18next';
import { useCallback } from 'react';
import useApi from './useApi';

const basePath = '/login';
const requestPath = `${basePath}/request`;

const useAuthenticationApi = () => {
  const { sendRequestWithBody } = useApi();
  const fetchSAMLRequest: () => Promise<AuthenticationForm> = useCallback(
    async () =>
      await sendRequestWithBody<AuthenticationRequest>('POST', requestPath, {
        language: t('i18n.luxtrustCode'),
      }),
    [],
  );
  return { fetchSAMLRequest };
};
export default useAuthenticationApi;
