import { isRouteErrorResponse, useRouteError, Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';

const ErrorPage = () => {
  const error = useRouteError();
  let errorDetails = <></>;
  if (isRouteErrorResponse(error)) {
    errorDetails = (
      <p>
        <i>{error.statusText || error.error?.message}</i>
      </p>
    );
  }
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      {errorDetails}
      <Link component={RouterLink} to={'/'}>
        Go back
      </Link>
    </div>
  );
};
export default ErrorPage;
