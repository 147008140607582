import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'api/paths';
import useApi from 'api/useApi';
import { Job } from 'models/job.model';
import { QUERY_KEYS } from './queryKeys';

export const useQueryJobs = () => {
  const { sendGetRequest } = useApi();
  return useQuery<Job[], Error>([QUERY_KEYS.JOB.ALL], async () => {
    return await sendGetRequest<Job[]>(API_PATH.JOBS);
  });
};
