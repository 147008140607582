import { Box, Link } from '@mui/material';
import PatientTable from 'components/PatientTable';
import PatientModal from 'components/modals/PatientModal';
import SearchBar from 'components/shared/SearchBar';
import WaitingRoomList from 'components/waitingRoom/WaitingRoomList';
import WaitingRoomModal from 'components/waitingRoom/WaitingRoomModal';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import StyledButton from 'components/StyledButton';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE } from 'router/routes';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';

const HomePage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEdition, setIsEdition] = useState(false);
  const [id, setId] = useState<string>('');
  const [filter, setFilter] = useState<string>('');

  const [isOpenWaitingRoom, setIsOpenWaitingRoom] = useState(false);
  const [waitingRoomId, setWaitingRoomId] = useState<string>('');
  const { t } = useTranslation();

  const { isOrganisationMember, centerId } = useContext(AuthenticationContext);

  useEffect(() => setIsOpenWaitingRoom(false), [centerId]);

  return (
    <Box
      component="main"
      sx={{
        position: 'relative',
        display: 'flex',
        gridTemplateColumns: '3fr 1fr',
        gridTemplateRows: '40px 1fr',
        gridColumnGap: '24px',
        gridRowGap: '32px',
        p: '32px 24px',
        m: 0,
        height: 'calc(100% - 64px)',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          width: '60%',
          flex: '1 1 auto',
        }}
      >
        <Box
          sx={{
            gridRow: 1,
            gridColumn: 1,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <SearchBar onSearch={setFilter} placeholder={t('patient.search')} />
          {!isOrganisationMember && (
            <StyledButton
              startIcon={<PersonAddAltOutlinedIcon />}
              variant="contained"
              onClick={() => {
                setIsEdition(false);
                setIsOpen(true);
              }}
            >
              {t('patient.add')}
            </StyledButton>
          )}
        </Box>

        <PatientTable
          sx={{ gridRow: 2, gridColumn: 1 }}
          filter={filter}
          editPatient={(id: string) => {
            setId(id);
            setIsEdition(true);
            setIsOpen(true);
          }}
        />

        <PatientModal
          id={id}
          isEdition={isEdition}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      </Box>

      {!isOrganisationMember && (
        <Box
          sx={{
            m: 0,
            p: 0,
            height: '100%',
            width: '350px',
            minWidth: '250px',
            gridRow: '1 / 3',
            gridColumn: 2,
            flex: '0 1 auto',
          }}
        >
          <Link component={RouterLink} to={ROUTE.HISTORY}>
            <StyledButton
              sx={{ width: '100%' }}
              startIcon={<DensitySmallIcon />}
              variant="contained"
            >
              {t('action.history')}
            </StyledButton>
          </Link>
          <WaitingRoomList
            sx={{
              marginTop: '16px',
              height: 'calc(100% - 56px)',
              width: '350px',
              minWidth: '250px',
              flex: '0 1 auto',
            }}
            onClick={(waitingroomId) => {
              setWaitingRoomId(waitingroomId);
              setIsOpenWaitingRoom(true);
            }}
          />
        </Box>
      )}

      {!isOrganisationMember && (
        <WaitingRoomModal
          isOpen={isOpenWaitingRoom}
          waitingRoomId={waitingRoomId}
          onClose={() => {
            setIsOpenWaitingRoom(false);
            setWaitingRoomId('');
          }}
        />
      )}
    </Box>
  );
};
export default HomePage;
