import { useQuery } from '@tanstack/react-query';
import { API_PATH } from 'api/paths';
import useApi from 'api/useApi';
import { Role } from 'models/role.model';
import { QUERY_KEYS } from './queryKeys';

export const useQueryRoles = () => {
  const { sendGetRequest } = useApi();
  return useQuery<Role[], Error>([QUERY_KEYS.ROLE.ALL], async () => {
    return await sendGetRequest<Role[]>(API_PATH.ROLES);
  });
};
