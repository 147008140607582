import { AuthenticationContext } from 'components/AuthenticationProvider';
import { Page } from 'models/api.model';
import { useCallback, useContext } from 'react';
import { API_PATH } from './paths';

export const apiPath = process.env.REACT_APP_API_URL;

const assertIsValid = async (response: Response) => {
  if (!response.ok) {
    const json = await response.json();
    throw new Error(json.code);
  }
};

const useApi = () => {
  const { isLogged, token } = useContext(AuthenticationContext);

  const CustomFetch = useCallback(
    // seems like eslint can't find RequestInit, even though VScode can
    // eslint-disable-next-line no-undef
    async (url: string, requestInit: RequestInit) =>
      fetch(encodeURI(apiPath + url), requestInit).then(async (response: Response) => {
        await assertIsValid(response);
        if (response.headers.get('content-type')?.includes('application/json')) {
          return response.json();
        }
      }),
    [fetch, assertIsValid],
  );

  const refreshBearerToken = useCallback(
    async (refreshToken: string) => {
      const init = {
        method: 'GET',
        headers: {
          Authorization: `refresh ${refreshToken}`,
        },
      };

      return await CustomFetch(API_PATH.REFRESH, init);
    },
    [CustomFetch],
  );

  const sendRequest = useCallback(
    // seems like eslint can't find RequestInit, even though VScode can
    // eslint-disable-next-line no-undef
    async (path: string, requestInit: RequestInit) => {
      if (isLogged) {
        requestInit.headers = {
          ...requestInit.headers,
          Authorization: `bearer ${token}`,
        };
      }
      return CustomFetch(path, requestInit);
    },
    [CustomFetch, isLogged, token],
  );

  /**
   * @template T - The type of resource to retrieve
   * @param {string} path - The api path of the resource
   * @param {number} page - The page number you want to query
   * @param {number} size - The size of the page you want to query
   * @param {string} filter - The filter you want to apply
   * @returns {Promise<Page<T>>} It contains `size` patients
   */
  const sendGetRequest = useCallback(
    async <T>(path: string, params?: { key: string; value: string }[]): Promise<T> => {
      if (params && params.length > 0) {
        path +=
          '?' +
          params
            .map((param) => `${encodeURIComponent(param.key)}=${encodeURIComponent(param.value)}`)
            .join('&');
      }
      const init = {
        method: 'GET',
      };
      return await sendRequest(path, init);
    },
    [sendRequest],
  );

  const sendRequestWithBody = useCallback(
    async <T>(type: 'POST' | 'PUT', path: string, data: T) => {
      const init = {
        method: type,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      return await sendRequest(path, init);
    },
    [sendRequest],
  );

  const sendDeleteRequest = useCallback(
    async (path: string) => {
      const init = {
        method: 'DELETE',
      };

      return await sendRequest(path, init);
    },
    [sendRequest],
  );

  /**
   * Retrieve a {@link Page} of a resource
   *
   * @template T - The type of resource to retrieve
   * @param {string} path - The api path of the resource
   * @param {number} page - The page number you want to query
   * @param {number} size - The size of the page you want to query
   * @param {string} filter - The filter you want to apply
   * @returns A {@link Promise<Page<T>>} that contains a maximum of {@link size} elements of type {@link T}
   */
  const getPage = useCallback(
    async <T>(path: string, page: number, size: number, filter: string) => {
      const params = [
        { key: 'page', value: String(page) },
        { key: 'size', value: String(size) },
        { key: 'filter', value: filter },
      ];
      const json = (await sendGetRequest(path, params)) as Page<T>;
      if (json.content === undefined || json.totalElements === undefined) {
        throw new Error();
      }
      return json;
    },
    [sendGetRequest],
  );

  return { sendGetRequest, sendRequestWithBody, sendDeleteRequest, getPage, refreshBearerToken };
};
export default useApi;
