import { CustomFormProps } from 'models/form.models';
import { Controller, Path } from 'react-hook-form';
import { MuiTelInput } from 'mui-tel-input';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const inputStyle = {
  'margin': 0,
  'background': 'transparent',
  'width': '100%',
  '& .MuiOutlinedInput-root': {
    '&.Mui-error ': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
    '& fieldset': {
      borderColor: 'primary.main',
    },
    '&:hover fieldset': {
      borderColor: 'orange',
    },
  },
};

const skeletonStyle = {
  margin: '0 0 1rem 0',
  width: '100%',
};

const CustomForm = <T extends object>({ fields, control, isLoading }: CustomFormProps<T>) => {
  if (isLoading) {
    return (
      <>
        {fields.map((field) => (
          <Skeleton
            key={`field-${field.label}`}
            animation={'pulse'}
            variant="rounded"
            height={40}
            sx={{
              ...skeletonStyle,
              gridRow: field.gridRow,
              gridColumn: field.gridColumn,
            }}
          />
        ))}
      </>
    );
  }
  return (
    <>
      {fields.map((field) => {
        if (field.type === 'tel') {
          return (
            <Box
              sx={{ gridRow: field.gridRow, gridColumn: field.gridColumn }}
              hidden={field.isHidden}
              key={`field-${field.label}`}
            >
              <FormLabel required={field.isRequired}>{field.label}</FormLabel>
              <Controller
                name={field.register.name as Path<T>}
                control={control}
                render={({ field: controlField }) => (
                  <MuiTelInput
                    disabled={field.isReadonly}
                    size="small"
                    defaultCountry="LU"
                    preferredCountries={['LU', 'FR', 'BE', 'DE']}
                    value={controlField.value}
                    onChange={controlField.onChange}
                    FormHelperTextProps={{ sx: { marginTop: 0, fontSize: '0.75rem' } }}
                    sx={inputStyle}
                    InputLabelProps={{ sx: { color: 'gray1.main' } }}
                    error={field.hasError}
                    helperText={field.hasError ? field.helperText : undefined}
                  />
                )}
              />
            </Box>
          );
        }

        if (field.type === 'date') {
          return (
            <Box
              sx={{ gridRow: field.gridRow, gridColumn: field.gridColumn }}
              hidden={field.isHidden}
              key={`field-${field.label}`}
            >
              <FormLabel required={field.isRequired}>{field.label}</FormLabel>
              <Controller
                name={field.register.name as Path<T>}
                control={control}
                render={({ field: controlField }) => (
                  <DatePicker
                    disabled={field.isReadonly}
                    disableOpenPicker={field.isReadonly}
                    value={controlField.value ? new Date(controlField.value) : undefined}
                    openTo="year"
                    onChange={(date) => {
                      try {
                        controlField.onChange(date);
                      } catch (error) {
                        controlField.onChange();
                      }
                    }}
                    sx={{
                      ...inputStyle,
                      '& .MuiFormHelperText-root': {
                        marginTop: 0,
                      },
                      '& .MuiIconButton-root': {
                        'backgroundColor': 'background.paper',
                        'color': 'gray1.main',
                        '&:hover ': {
                          backgroundColor: 'background.paper',
                        },
                      },
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: field.hasError,
                        helperText: field.hasError ? field.helperText : undefined,
                      },
                    }}
                  />
                )}
              />
            </Box>
          );
        }

        if (field.type === 'multiselect') {
          return (
            <Box
              sx={{ gridRow: field.gridRow, gridColumn: field.gridColumn }}
              hidden={field.isHidden}
              key={`field-${field.label}`}
            >
              <FormLabel required={field.isRequired}>{field.label}</FormLabel>
              <Controller
                name={field.register.name as Path<T>}
                control={control}
                render={({ field: controlField }) => (
                  <FormControl fullWidth sx={inputStyle} size="small" error={field.hasError}>
                    <Autocomplete
                      multiple
                      sx={{
                        ...inputStyle,
                      }}
                      size="small"
                      options={field.options}
                      disabled={field.isReadonly}
                      disableCloseOnSelect
                      value={field.options.filter((option) =>
                        (controlField.value as string[]).includes(option.value),
                      )}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, data) =>
                        controlField.onChange(data.map((option) => option.value))
                      }
                      renderOption={(props, option, { selected }) => (
                        <MenuItem {...props}>
                          <Checkbox
                            key={`${option.value}-${option.label}`}
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.label}
                        </MenuItem>
                      )}
                      renderInput={(params) => <TextField {...params} />}
                    />
                    <FormHelperText>{field.hasError ? field.helperText : undefined}</FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
          );
        }

        if (field.type === 'boolean') {
          return (
            <Controller
              key={`field-${field.label}`}
              name={field.register.name as Path<T>}
              control={control}
              render={({ field: controlField }) => (
                <FormControlLabel
                  label={field.label}
                  sx={{
                    ...inputStyle,
                    gridRow: field.gridRow,
                    gridColumn: field.gridColumn,
                    color: 'gray1.main',
                  }}
                  control={
                    <Checkbox
                      disabled={field.isReadonly}
                      checked={controlField.value ?? false}
                      onChange={(e) => controlField.onChange(e.target.checked ?? false)}
                    />
                  }
                />
              )}
            />
          );
        }

        if (field.type === 'select') {
          return (
            <Box
              sx={{ gridRow: field.gridRow, gridColumn: field.gridColumn }}
              hidden={field.isHidden}
              key={`field-${field.label}`}
            >
              <FormLabel required={field.isRequired}>{field.label}</FormLabel>
              <Controller
                name={field.register.name as Path<T>}
                control={control}
                render={({ field: controlField }) => (
                  <FormControl fullWidth sx={inputStyle} size="small" error={field.hasError}>
                    <Select value={controlField?.value ?? ''}>
                      {field.options?.map((option) => {
                        const id = option.value ?? 'default';
                        return (
                          <MenuItem
                            key={`select-${id}`}
                            value={id}
                            onClick={() => controlField.onChange(option.value)}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{field.hasError ? field.helperText : undefined}</FormHelperText>
                  </FormControl>
                )}
              />
            </Box>
          );
        }

        if (field.type === 'text') {
          return (
            <Box
              sx={{ gridRow: field.gridRow, gridColumn: field.gridColumn }}
              hidden={field.isHidden}
              key={`field-${field.label}`}
            >
              <FormLabel required={field.isRequired}>{field.label}</FormLabel>
              <Controller
                name={field.register.name as Path<T>}
                control={control}
                render={({ field: controlField }) => (
                  <TextField
                    size="small"
                    value={controlField?.value ?? ''}
                    onChange={controlField.onChange}
                    sx={{ ...inputStyle }}
                    error={field.hasError}
                    FormHelperTextProps={{ sx: { marginTop: 0 } }}
                    onBlur={(e) => {
                      controlField.onChange(e.target.value.trim());
                      controlField.onBlur();
                    }}
                    helperText={field.hasError ? field.helperText : undefined}
                    rows={field.rows}
                    multiline={field.isMultiline}
                    inputProps={{ maxLength: field.maxLength }}
                    InputProps={{
                      readOnly: field.isReadonly,
                      endAdornment: <InputAdornment position="end">{field.endIcon}</InputAdornment>,
                    }}
                    disabled={field.isReadonly}
                  />
                )}
              />
            </Box>
          );
        }

        if (field.type === 'autocomplete') {
          return (
            <Box
              sx={{ gridRow: field.gridRow, gridColumn: field.gridColumn }}
              key={`field-${field.label}`}
            >
              <FormLabel required={field.isRequired}>{field.label}</FormLabel>
              <Controller
                name={field.register.name as Path<T>}
                control={control}
                render={({ field: controlField }) => (
                  <Autocomplete
                    size="small"
                    sx={{ ...inputStyle }}
                    getOptionLabel={(option) => option ?? ''}
                    isOptionEqualToValue={(option, value) => {
                      return option === value || value === '';
                    }}
                    options={field.autocompleteValues ?? []}
                    freeSolo={field.freeSolo}
                    value={controlField.value ?? null}
                    onChange={(_, value) => controlField.onChange(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={field.hasError}
                        onChange={(value) => controlField.onChange(value.target.value)}
                        helperText={field.hasError && field.helperText}
                      />
                    )}
                  />
                )}
              />
            </Box>
          );
        }

        return <></>;
      })}
    </>
  );
};
export default CustomForm;
