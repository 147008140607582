import { IconButton, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useDeleteCenter, useQueryCentersPage } from 'api';
import DataGridCustom from 'components/dataGrid/DataGridCustom';
import { TableState } from 'models/dataGridCustom.model';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { NotificationContext } from 'components/NotificationProvider';
import { ModalContext } from 'components/ModalProvider';
import { emptyPage } from 'models/api.model';
import { Center } from 'models/center.model';

const CenterTable = ({
  filter,
  editCenter,
}: {
  filter: string;
  editCenter: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { openModal } = useContext(ModalContext);
  const [pageState, setPageState] = useState<TableState>({
    page: 0,
    pageSize: 0,
  });
  // Set cache time to 0 to disable request caching
  // since there's a bug with MUI data grid when loading the page with cached data
  const { isLoading, data } = useQueryCentersPage(pageState.page, pageState.pageSize, filter, {
    cacheTime: 0,
  });
  const { mutateAsync: deleteCenter } = useDeleteCenter();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('center.name'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'address.street',
      headerName: t('center.street'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Center, GridTreeNodeWithRender>,
      ) => params.row.addressDto.street,
    },
    {
      field: 'address.complement',
      headerName: t('center.complement'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Center, GridTreeNodeWithRender>,
      ) => params.row.addressDto.complement,
    },
    {
      field: 'address.city.zipCode',
      headerName: t('center.zipCode'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Center, GridTreeNodeWithRender>,
      ) => params.row.addressDto.zipCode,
    },
    {
      field: 'address.city.name',
      headerName: t('center.city'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Center, GridTreeNodeWithRender>,
      ) => params.row.addressDto.cityDto.name,
    },
    {
      field: 'address.city.country',
      headerName: t('center.country'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Center, GridTreeNodeWithRender>,
      ) => params.row.addressDto.cityDto.countryDto.name,
    },
    {
      field: 'action',
      width: 100,
      headerName: t('action.actions').toString(),
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Center, GridTreeNodeWithRender>,
      ) => {
        return (
          <>
            <Tooltip title={t('action.edit')} arrow>
              <IconButton
                onClick={() => {
                  editCenter(params.id.toString());
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('action.delete')} arrow>
              <IconButton
                onClick={() => {
                  openModal({
                    title: t('modals.deleteCenter.title', {
                      name: params.row.name,
                    }).toString(),
                    message: t('modals.deleteCenter.message'),
                    cancelButton: {
                      label: t('action.cancel').toString(),
                      onClick: () => {
                        return;
                      },
                    },
                    validButton: {
                      label: t('action.delete').toString(),
                      onClick: async () => {
                        await deleteCenter(params.id.toString());
                        sendNotification(t('notifications.centerTable.delete'), 'success');
                      },
                    },
                    onClose: () => {
                      return;
                    },
                  });
                }}
              >
                <DeleteForeverIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setPageState({ ...pageState, page: 0 });
  }, [filter]);

  return (
    <DataGridCustom
      isPaginated={true}
      data={data ?? emptyPage}
      isLoading={isLoading}
      state={pageState}
      columns={columns}
      getRowId={(row) => row.id}
      onPaginationModelChange={(newPageModel: TableState) => {
        setPageState({
          page: newPageModel.page,
          pageSize: newPageModel.pageSize,
        });
      }}
    />
  );
};
export default CenterTable;
