import { Box, Radio, SvgIcon, Typography } from '@mui/material';
import { ReactComponent as WaitingIcon } from 'assets/WaitingIcon.svg';
import { ReactComponent as DoctorIcon } from 'assets/DoctorIcon.svg';
import { ReactComponent as SocialWorkerIcon } from 'assets/SocialWorkerIcon.svg';
import { ReactComponent as OtherIcon } from 'assets/OtherIcon.svg';
import { WAITING_ROOM_COLOR, WaitingRoomStatus } from 'models/waitingRoom.model';
import { useMemo } from 'react';
import useDynamicTranslation from 'hooks/useDynamicTranslation';

type StatusButtonProps = {
  status: WaitingRoomStatus;
  isCheck: boolean;
  onClick: () => void;
};

const StatusButton = ({ status, isCheck, onClick }: StatusButtonProps) => {
  const dynamicTranslation = useDynamicTranslation();

  const statusLabel = useMemo(() => {
    // lower first letter to ensure it matches the translation key format (camel case)
    const key = `modals.waitingRoom.status.${status.charAt(0).toLowerCase()}${status.slice(1)}`;
    return dynamicTranslation(key) ?? '';
  }, [status, dynamicTranslation]);

  const iconProps = useMemo(() => {
    switch (status) {
      case WaitingRoomStatus.ConsultingDoctor:
        return { component: DoctorIcon, viewBox: '0 0 32 32' };
      case WaitingRoomStatus.ConsultingSocialWorker:
        return { component: SocialWorkerIcon, viewBox: '0 0 33 33' };
      case WaitingRoomStatus.ConsultingOther:
        return { component: OtherIcon, viewBox: '0 0 29 29' };
      default:
        return { component: WaitingIcon, viewBox: '0 0 24 24' };
    }
  }, [status]);

  return (
    <Box
      onClick={onClick}
      sx={{
        'display': 'flex',
        'flexDirection': 'row',
        'width': '180px',
        ':hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: `${WAITING_ROOM_COLOR[status]}`,
          width: '10px',
          borderRadius: '15px 0 0 15px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '170px',
          paddingTop: '16px',
          borderRadius: '0 15px 15px 0',
          ...(isCheck
            ? {
                backgroundColor: `color-mix(in srgb, ${WAITING_ROOM_COLOR[status]} 5%, white)`,
              }
            : {
                backgroundColor: 'white',
                boxShadow: '5px 5px 10px rgba(0, 0, 0, 0.05)',
              }),
        }}
      >
        <SvgIcon {...iconProps} sx={{ fontSize: '24px', zIndex: 2, transition: '0.2s' }} />

        <Typography
          sx={{
            left: '80px',
            textAlign: 'center',
            verticalAlign: 'center',
            fontWeight: 400,
            whiteSpace: 'nowrap',
            marginTop: '10px',
          }}
        >
          {statusLabel}
        </Typography>

        <Radio checked={isCheck} />
      </Box>
    </Box>
  );
};

export default StatusButton;
