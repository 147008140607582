import { useCreateOrganisation, useModifyOrganisation, useQueryOrganisation } from 'api';
import { NotificationContext } from 'components/NotificationProvider';
import useCustomForm from 'hooks/useCustomForm';
import { CustomField } from 'models/form.models';
import { CustomFormModalProps } from 'models/modal.model';
import { Organisation } from 'models/organisation.model';
import { useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import FormModal from 'components/modals/FormModal';
import { useQueryCountries } from 'api/country.api';
import { useQueryCities } from 'api/city.api';
import useOrganisationValidator from 'hooks/useOrganisationValidator';

const organisationEmpty: Organisation = {
  id: '',
  name: '',
  addressDto: {
    id: '',
    street: '',
    complement: null,
    zipCode: '',
    cityDto: {
      id: '',
      name: '',
      countryDto: {
        id: '',
        name: '',
        isoCode: null,
      },
    },
  },
};

const OrganisationModal = ({
  id,
  isEdition,
  isOpen,
  onClose,
}: CustomFormModalProps<Organisation>) => {
  const { t } = useTranslation();
  const { mutateAsync: createOrganisation } = useCreateOrganisation();
  const { sendNotification } = useContext(NotificationContext);
  const { isLoading: areCountriessLoading, data: countries } = useQueryCountries();
  const { isLoading: areCitiesLoading, data: cities } = useQueryCities();
  const { mutateAsync: modifyOrganisation } = useModifyOrganisation(id);
  const { isLoading: isOrganisationLoading, data: organisation } = useQueryOrganisation(id, {
    enabled: isEdition,
  });
  const isLoading = useMemo(
    () => (isEdition && isOrganisationLoading) || areCountriessLoading || areCitiesLoading,
    [isOrganisationLoading, areCountriessLoading, areCitiesLoading],
  );
  const data = useMemo(
    () => (isEdition && organisation ? organisation : organisationEmpty),
    [isEdition, organisation],
  );
  const cancelModalTitle = useMemo(
    () =>
      t(isEdition ? 'modals.organisationForm.title.edit' : 'modals.organisationForm.title.add', {
        returnObjects: true,
      }),
    [t],
  );

  const organisationValidator = useOrganisationValidator();
  const { control, handleSubmit, isDirty, register, errors } = useCustomForm<Organisation>({
    isEdition,
    isOpen,
    data: data,
    schema: organisationValidator,
    mutate: isEdition ? modifyOrganisation : createOrganisation,
  });

  const onSaved = useCallback(() => {
    isEdition
      ? sendNotification(t('notifications.organisationTable.modify'), 'success')
      : sendNotification(t('notifications.organisationTable.add'), 'success');
    onClose();
  }, [onClose, sendNotification, t]);

  const fields: CustomField[] = [
    {
      type: 'text',
      hasError: Boolean(errors.name),
      gridRow: 1,
      gridColumn: 1,
      helperText: errors.name?.message,
      label: t('organisation.name'),
      register: register('name'),
      isRequired: !organisationValidator.shape.name.isNullable(),
      maxLength: 50,
    },
    {
      type: 'text',
      hasError: Boolean(errors.addressDto?.street),
      gridRow: 1,
      gridColumn: 2,
      helperText: errors.addressDto?.street?.message,
      label: t('organisation.street'),
      register: register('addressDto.street'),
      isRequired: !organisationValidator.shape.addressDto.shape.street.isNullable(),
      maxLength: 70,
    },
    {
      type: 'text',
      hasError: Boolean(errors.addressDto?.complement),
      gridRow: 2,
      gridColumn: 1,
      helperText: errors.addressDto?.complement?.message,
      label: t('organisation.complement'),
      register: register('addressDto.complement'),
      isRequired: !organisationValidator.shape.addressDto.shape.complement.isNullable(),
      maxLength: 30,
    },
    {
      type: 'text',
      hasError: Boolean(errors.addressDto?.zipCode),
      gridRow: 2,
      gridColumn: 2,
      helperText: errors.addressDto?.zipCode?.message,
      label: t('organisation.zipCode'),
      register: register('addressDto.zipCode'),
      isRequired: !organisationValidator.shape.addressDto.shape.zipCode.isNullable(),
    },
    {
      type: 'autocomplete',
      hasError: Boolean(errors.addressDto?.cityDto?.name),
      gridRow: 3,
      gridColumn: 1,
      helperText: errors.addressDto?.cityDto?.name?.message,
      label: t('organisation.city'),
      register: register('addressDto.cityDto.name'),
      isRequired: !organisationValidator.shape.addressDto.shape.cityDto.shape.name.isNullable(),
      autocompleteValues:
        cities
          ?.map((city) => city.name)
          .filter((value, index, self) => index === self.findIndex((city) => city === value)) ?? [],
      freeSolo: true,
    },
    {
      type: 'autocomplete',
      hasError: Boolean(errors.addressDto?.cityDto?.countryDto?.name),
      gridRow: 3,
      gridColumn: 2,
      helperText: errors.addressDto?.cityDto?.countryDto?.name?.message,
      label: t('organisation.country'),
      register: register('addressDto.cityDto.countryDto.name'),
      isRequired:
        !organisationValidator.shape.addressDto.shape.cityDto.shape.countryDto.shape.name.isNullable(),
      autocompleteValues: countries?.map((country) => country.name) ?? [],
      freeSolo: false,
    },
  ];
  return (
    <FormModal<Organisation>
      title={isEdition ? t('organisation.edit') : t('organisation.add')}
      control={control}
      isEdition={isEdition}
      isOpen={isOpen}
      onValid={handleSubmit(onSaved)}
      isDirty={isDirty}
      fields={fields}
      handleClose={onClose}
      cancelModalTitle={cancelModalTitle}
      isLoading={isLoading}
    />
  );
};

export default OrganisationModal;
