import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';

const StyledIconButton = styled(IconButton)<IconButtonProps>(() => {
  const theme = useTheme();
  return {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };
});
export default StyledIconButton;
