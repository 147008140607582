import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const StyledButton = styled(Button)<ButtonProps>(() => ({
  padding: '8px 16px',
  height: '40px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.12)',
  borderRadius: '8px',
}));
export default StyledButton;
