import { matchIsValidTel } from 'mui-tel-input';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const usePatientValidator = () => {
  const { t } = useTranslation();
  const patientValidator = z.object({
    id: z.string(),
    dsiNumber: z.string().length(12, { message: t('form.requiredSize', { size: 12 }) }),
    lastname: z
      .string()
      .min(1, { message: t('form.required') })
      .max(100, { message: t('form.maxSize', { max: 100 }) }),
    firstname: z
      .string()
      .min(1, { message: t('form.required') })
      .max(20, { message: t('form.maxSize', { max: 20 }) }),
    birthDate: z.coerce
      .date({
        required_error: t('form.error', {
          context: 'female',
          field: t('patient.birthdate'),
        }),
      })
      .min(new Date(1900, 0, 1), {
        message: t('form.dateAfter', {
          date: new Date(1900, 0, 1).toLocaleString().split(' ')[0],
        }),
      })
      .max(new Date(), {
        message: t('form.dateAfter', {
          date: new Date().toLocaleString().split(' ')[0],
        }),
      })
      .nullable(),
    serialNumber: z.string().nullable(),
    email: z
      .string()
      .email({ message: t('form.error', { field: t('patient.email') }) })
      .or(z.string().length(0))
      .nullable(),
    contactNumber: z
      .string()
      .refine((number) => /^\+(\d{1,3})$/.test(number) || matchIsValidTel(number), {
        message: t('form.error', { field: t('patient.contactNumber') }),
      })
      .nullable(),
    hasGivenAuthorisation: z.boolean(),
    remark: z.string().nullable(),
    isInWaitingRoom: z.boolean(),
    organisationsIds: z.array(z.string()),
    lastConsultationCenter: z.string().nullable(),
    lastConsultationDate: z.coerce.date().nullable(),
  });
  return patientValidator;
};
export default usePatientValidator;
