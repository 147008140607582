import { QueryKey, UseQueryOptions } from '@tanstack/react-query';

export type TanstackQueryOptions<T> = Omit<
  UseQueryOptions<T, Error, T, QueryKey>,
  'queryKey' | 'queryFn' | 'initialData'
> & { initialData?: () => undefined };

export type Page<T> = {
  content: T[];
  totalElements: number;
};

export const emptyPage: Page<never> = {
  content: [],
  totalElements: 0,
};
