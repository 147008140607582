import { Box, Link } from '@mui/material';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import StyledButton from 'components/StyledButton';
import WaitingRoomList from 'components/waitingRoom/WaitingRoomList';
import WaitingRoomModal from 'components/waitingRoom/WaitingRoomModal';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTE } from 'router/routes';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const HistoryPage = () => {
  const { t } = useTranslation();
  const [id, setId] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const { centerId } = useContext(AuthenticationContext);

  useEffect(() => setIsOpen(false), [centerId]);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '24px',
        p: '32px 24px',
        m: 0,
        height: 'calc(100% - 64px)',
        width: '100vw',
      }}
    >
      <Box
        sx={{
          m: 0,
          p: 0,
          height: '100%',
          width: '350px',
          minWidth: '250px',
          gridRow: '1 / 3',
          gridColumn: 2,
          flex: '0 1 auto',
        }}
      >
        <Link component={RouterLink} to={ROUTE.PATIENTS.INDEX}>
          <StyledButton sx={{ width: '100%' }} startIcon={<ArrowBackIcon />} variant="contained">
            {t('action.back')}
          </StyledButton>
        </Link>
        <WaitingRoomList
          sx={{
            marginTop: '16px',
            height: 'calc(100% - 56px)',
            width: '350px',
            minWidth: '250px',
            flex: '0 1 auto',
          }}
          isListHistoricized={true}
          onClick={(waitingRoomId) => {
            setId(waitingRoomId);
            setIsOpen(true);
          }}
        />
      </Box>
      <Box sx={{ flex: '1 1 auto', margin: 0, padding: 0 }}>
        <WaitingRoomModal
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}
          isOpen={isOpen}
          waitingRoomId={id}
          isListHistoricized={true}
          onClose={() => {
            setIsOpen(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default HistoryPage;
