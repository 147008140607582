import { Box } from '@mui/material';
import StyledButton from 'components/StyledButton';
import SearchBar from 'components/shared/SearchBar';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CenterTable from 'components/administrationPanel/centers/CenterTable';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import CenterModal from 'components/administrationPanel/centers/CenterModal';
import AdministrationNavigation from '../components/administrationPanel/AdministrationNavigation';

const CenterPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string>('');
  const [isEdition, setIsEdition] = useState(false);
  const [filter, setFilter] = useState<string>('');
  const { t } = useTranslation();

  return (
    <Box
      component="main"
      sx={{
        p: '0 24px 32px 24px',
        m: 0,
        height: 'calc(100% - 64px)',
        width: '100vw',
      }}
    >
      <AdministrationNavigation currentPage={t('administrationPanel.centers')} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <SearchBar onSearch={setFilter} placeholder={t('center.search')} />

        <StyledButton
          startIcon={<PersonAddAltOutlinedIcon />}
          variant="contained"
          onClick={() => {
            setIsEdition(false);
            setIsOpen(true);
          }}
        >
          {t('center.add')}
        </StyledButton>
      </Box>

      <Box sx={{ height: 'inherit' }}>
        <CenterTable
          filter={filter}
          editCenter={(id: string) => {
            setId(id);
            setIsEdition(true);
            setIsOpen(true);
          }}
        />
      </Box>

      <CenterModal id={id} isEdition={isEdition} isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  );
};
export default CenterPage;
