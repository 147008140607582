import { NotificationContext } from 'components/NotificationProvider';
import useCustomForm from 'hooks/useCustomForm';
import { CustomField } from 'models/form.models';
import { CustomFormModalProps } from 'models/modal.model';
import { useContext, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Center } from 'models/center.model';
import FormModal from 'components/modals/FormModal';
import useCenterValidator from 'hooks/useCenterValidator';
import {
  useQueryCities,
  useCreateCenter,
  useModifyCenter,
  useQueryCenter,
  useQueryCountries,
} from 'api';

const centerEmpty: Center = {
  id: '',
  name: '',
  addressDto: {
    id: '',
    street: '',
    complement: null,
    zipCode: '',
    cityDto: {
      id: '',
      name: '',
      countryDto: {
        id: '',
        name: '',
        isoCode: '',
      },
    },
  },
};

const CenterModal = ({ id, isEdition, isOpen, onClose }: CustomFormModalProps<Center>) => {
  const { t } = useTranslation();
  const { mutateAsync: createCenter } = useCreateCenter();
  const { sendNotification } = useContext(NotificationContext);
  const { isLoading: areountriesLoading, data: countries } = useQueryCountries();
  const { isLoading: areCitiesLoading, data: cities } = useQueryCities();
  const { mutateAsync: modifyCenter } = useModifyCenter(id);
  const { isLoading: isCenterLoading, data: center } = useQueryCenter(id, {
    enabled: isEdition,
  });

  const isLoading = useMemo(
    () => (isEdition && isCenterLoading) || areountriesLoading || areCitiesLoading,
    [isCenterLoading, areountriesLoading, areCitiesLoading],
  );
  const data = useMemo(() => (isEdition && center ? center : centerEmpty), [isEdition, center]);

  const centerValidator = useCenterValidator();
  const { control, handleSubmit, isDirty, register, errors } = useCustomForm<Center>({
    isEdition,
    isOpen,
    schema: centerValidator,
    data: data,
    mutate: isEdition ? modifyCenter : createCenter,
  });

  const cancelModalTitle = useMemo(
    () => t(isEdition ? 'modals.centerForm.title.edit' : 'modals.centerForm.title.add'),
    [t],
  );
  const onSaved = useCallback(() => {
    isEdition
      ? sendNotification(t('notifications.centerTable.modify'), 'success')
      : sendNotification(t('notifications.centerTable.add'), 'success');
    onClose();
  }, [onClose, sendNotification, t]);

  const fields: CustomField[] = [
    {
      type: 'text',
      hasError: Boolean(errors.name),
      gridRow: 1,
      gridColumn: 1,
      helperText: errors.name?.message,
      label: t('center.name'),
      register: register('name'),
      isRequired: !centerValidator.shape.name.isNullable(),
      maxLength: 50,
    },
    {
      type: 'text',
      hasError: Boolean(errors.addressDto?.street),
      gridRow: 1,
      gridColumn: 2,
      helperText: errors.addressDto?.street?.message,
      label: t('center.street'),
      register: register('addressDto.street'),
      isRequired: !centerValidator.shape.addressDto.shape.street.isNullable(),
      maxLength: 70,
    },
    {
      type: 'text',
      hasError: Boolean(errors.addressDto?.complement),
      gridRow: 2,
      gridColumn: 1,
      helperText: errors.addressDto?.complement?.message,
      label: t('center.complement'),
      register: register('addressDto.complement'),
      isRequired: !centerValidator.shape.addressDto.shape.complement.isNullable(),
    },
    {
      type: 'text',
      hasError: Boolean(errors.addressDto?.zipCode),
      gridRow: 2,
      gridColumn: 2,
      helperText: errors.addressDto?.zipCode?.message,
      label: t('center.zipCode'),
      register: register('addressDto.zipCode'),
      isRequired: !centerValidator.shape.addressDto.shape.zipCode.isNullable(),
    },
    {
      type: 'autocomplete',
      hasError: Boolean(errors.addressDto?.cityDto?.name),
      gridRow: 3,
      gridColumn: 1,
      helperText: errors.addressDto?.cityDto?.name?.message,
      label: t('center.city'),
      register: register('addressDto.cityDto.name'),
      isRequired: !centerValidator.shape.addressDto.shape.cityDto.shape.name.isNullable(),
      autocompleteValues:
        cities
          ?.map((city) => city.name)
          .filter((value, index, self) => index === self.findIndex((city) => city === value)) ?? [],
      freeSolo: true,
    },
    {
      type: 'autocomplete',
      hasError: Boolean(errors.addressDto?.cityDto?.countryDto?.name),
      gridRow: 3,
      gridColumn: 2,
      helperText: errors.addressDto?.cityDto?.countryDto?.name?.message,
      label: t('center.country'),
      isRequired:
        !centerValidator.shape.addressDto.shape.cityDto.shape.countryDto.shape.name.isNullable(),
      register: register('addressDto.cityDto.countryDto.name'),
      autocompleteValues: countries?.map((country) => country.name) ?? [],
      freeSolo: false,
    },
  ];
  return (
    <FormModal<Center>
      title={isEdition ? t('center.edit') : t('center.add')}
      control={control}
      isEdition={isEdition}
      isOpen={isOpen}
      onValid={handleSubmit(onSaved)}
      isDirty={isDirty}
      fields={fields}
      handleClose={onClose}
      cancelModalTitle={cancelModalTitle}
      isLoading={isLoading}
    />
  );
};

export default CenterModal;
