import { Box, Skeleton } from '@mui/material';
import { LoadingOverlayPropsOverrides } from '@mui/x-data-grid';

const SkeletonTable = ({ columnsSize, rowsCount }: LoadingOverlayPropsOverrides) => (
  <>
    {[...Array(rowsCount)].map((_, rowIndex) => (
      <Box display="flex" key={`skeletonRow${rowIndex}`} height={`${100 / rowsCount}%`}>
        {columnsSize.map((value, columnIndex) => (
          <Box
            key={`skeletonCell${rowIndex}-${columnIndex}`}
            width={value.flex === 0 ? value.width : undefined}
            flex={value.flex === 0 ? undefined : value.flex}
            height="100%"
          >
            <Skeleton sx={{ margin: '1em' }} />
          </Box>
        ))}
      </Box>
    ))}
  </>
);

export default SkeletonTable;
