const patientsPath = '/patient';
const administrationPath = '/administration';
const administrationCentersPath = `${administrationPath}/centers`;
const administrationOrganisationsPath = `${administrationPath}/organisations`;
const administrationUsersPath = `${administrationPath}/users`;

export const ROUTE = {
  ADMINISTRATION: {
    INDEX: `${administrationPath}`,
    CENTERS: {
      ADD: `${administrationCentersPath}/add`,
      EDIT: (id: string) => `${administrationCentersPath}/${id}`,
      INDEX: `${administrationCentersPath}`,
    },
    ORGANISATIONS: {
      ADD: `${administrationOrganisationsPath}/add`,
      EDIT: (id: string) => `${administrationOrganisationsPath}/${id}`,
      INDEX: `${administrationOrganisationsPath}`,
    },
    USERS: {
      ADD: `${administrationUsersPath}/add`,
      EDIT: (id: string) => `${administrationUsersPath}/${id}`,
      INDEX: `${administrationUsersPath}`,
    },
  },
  HISTORY: '/history',
  LOGIN: '/login',
  PATIENTS: {
    ADD: `${patientsPath}/add`,
    EDIT: (id: string) => `${patientsPath}/${id}`,
    INDEX: '/',
    WAITING_ROOM: (id: string) => `/waiting/${id}`,
  },
};
