import { Pagination } from '@mui/material';
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid';
import PaginationItem from '@mui/material/PaginationItem';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';

const PaginationCustom = () => {
  const [pageCount, setPageCount] = useState(1);
  const apiRef = useGridApiContext();
  const rootProps = useGridRootProps();
  const page = useMemo(
    () => rootProps.paginationModel?.page ?? 1,
    [rootProps.paginationModel?.page],
  );
  const pageSize = useMemo(
    () => rootProps.paginationModel?.pageSize ?? 0,
    [rootProps.paginationModel?.pageSize],
  );

  useEffect(() => {
    const rowCount = rootProps.rowCount ?? 0;
    if (rowCount > 0 || !rootProps.loading) {
      setPageCount(pageSize > 0 && rowCount > 0 ? Math.ceil((rowCount ?? 0) / pageSize) : 0);
    }
  }, [rootProps.loading, rootProps.rowCount]);

  return (
    <Pagination
      page={page + 1}
      count={pageCount}
      // @ts-expect-error copied from MUI documentation
      renderItem={(props) => <PaginationItem {...props} disableRipple />}
      onChange={(event: ChangeEvent<unknown>, value: number) => apiRef.current.setPage(value - 1)}
    />
  );
};
export default PaginationCustom;
