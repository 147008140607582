import { useEffect } from 'react';
import { AuthenticationForm } from 'models/authentication.model';
import Loading from './shared/Loader';
import useAuthenticationApi from 'api/useAuthenticationApi';

const LuxtrustAuthenticator = ({ onError }: { onError: () => void }) => {
  const { fetchSAMLRequest } = useAuthenticationApi();
  useEffect(() => {
    const getSamlRequest = async () => {
      const authenticationForm: AuthenticationForm = await fetchSAMLRequest();

      (document.getElementById('SAMLRequest') as HTMLInputElement).value =
        authenticationForm.samlRequest;

      const form: HTMLFormElement = document.getElementById(
        'luxtrustAuthenticationForm',
      ) as HTMLFormElement;
      form.action = authenticationForm.url;
      form.submit();
    };
    getSamlRequest().catch(onError);
  }, []);

  // An iframe can be used to prevent navigating outside our SPA,
  // allowing to keep control on the app flow and prevent refresh.
  // This iframe needs to be watched to reroot on the main application,
  // or it may cause some iframe inception.

  return (
    <>
      <form
        style={{ display: 'none' }}
        id="luxtrustAuthenticationForm"
        // target="luxtrustAuthenticationFrame"
        method="POST"
      >
        <input type="hidden" name="SAMLRequest" id="SAMLRequest" />
      </form>
      <Loading />
      {/* <iframe
        style={{ width: '100vw', height: '100vh' }}
        name="luxtrustAuthenticationFrame"
      ></iframe> */}
    </>
  );
};
export default LuxtrustAuthenticator;
