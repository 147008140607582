import { Outlet, createBrowserRouter } from 'react-router-dom';
import { ROUTE } from 'router/routes';
import ErrorPage from 'pages/ErrorPage';
import Layout from 'components/shared/Layout';
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import HistoryPage from 'pages/HistoryPage';
import AdministrationPage from 'pages/AdministrationPage';
import UserPage from 'pages/UserPage';
import OrganisationPage from 'pages/OrganisationPage';
import CenterPage from 'pages/CenterPage';

const router = createBrowserRouter([
  {
    errorElement: <ErrorPage />,
    element: <Outlet />,
    children: [
      {
        path: ROUTE.LOGIN,
        element: <LoginPage />,
      },
      {
        element: <Layout />,
        children: [
          {
            path: ROUTE.PATIENTS.INDEX,
            element: <HomePage />,
          },
          {
            path: ROUTE.HISTORY,
            element: <HistoryPage />,
          },
          {
            path: ROUTE.ADMINISTRATION.INDEX,
            children: [
              {
                path: '',
                element: <AdministrationPage />,
              },
              {
                path: ROUTE.ADMINISTRATION.USERS.INDEX,
                element: <UserPage />,
              },
              {
                path: ROUTE.ADMINISTRATION.ORGANISATIONS.INDEX,
                element: <OrganisationPage />,
              },
              {
                path: ROUTE.ADMINISTRATION.CENTERS.INDEX,
                element: <CenterPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
export default router;
