import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'router';
import 'i18n';
import GetTheme from 'theme';
import { useTranslation } from 'react-i18next';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import NotificationProvider from 'components/NotificationProvider';
import AuthenticationProvider from 'components/AuthenticationProvider';
import ModalProvider from 'components/ModalProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as dateFnsLocal from 'date-fns/locale';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: 300000, refetchOnWindowFocus: false },
  },
});

const App = () => {
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState(createTheme());
  const [dateFnsLocalisationCode, setDateFnsLocalisationCode] =
    useState<keyof typeof dateFnsLocal>('fr');

  // Re-generate the theme with MUI translation on i18n language change
  useEffect(() => {
    setTheme(GetTheme());
    setDateFnsLocalisationCode(t('i18n.dateFnsCode') as keyof typeof dateFnsLocal);
  }, [i18n.language]);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <NotificationProvider>
          <AuthenticationProvider>
            <ModalProvider>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={dateFnsLocal[dateFnsLocalisationCode]}
              >
                <QueryClientProvider client={queryClient}>
                  <RouterProvider router={router} />
                </QueryClientProvider>
              </LocalizationProvider>
            </ModalProvider>
          </AuthenticationProvider>
        </NotificationProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
};
export default App;
