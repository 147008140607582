import { useTranslation } from 'react-i18next';
import { z } from 'zod';

const useCountryValidator = () => {
  const { t } = useTranslation();
  const countryValidator = z.object({
    id: z.string(),
    name: z
      .string()
      .min(1, { message: t('form.required') })
      .max(50, { message: t('form.maxSize', { max: 50 }) }),
    isoCode: z.string().nullable(),
  });
  return countryValidator;
};
export default useCountryValidator;
