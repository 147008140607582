import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Organisation } from 'models/organisation.model';
import useApi from 'api/useApi';
import { API_PATH } from 'api/paths';
import { QUERY_KEYS } from './queryKeys';
import { Page, TanstackQueryOptions } from 'models/api.model';

export const useQueryOrganisations = () => {
  const { sendGetRequest } = useApi();
  return useQuery<Organisation[], Error>([QUERY_KEYS.ORGANISATION.ALL], async () =>
    sendGetRequest<Organisation[]>(API_PATH.ORGANISATIONS),
  );
};

export const useQueryOrganisationsPage = (
  page: number,
  size: number,
  filter: string,
  options?: TanstackQueryOptions<Page<Organisation>>,
) => {
  const { getPage } = useApi();
  return useQuery<Page<Organisation>, Error>(
    [QUERY_KEYS.ORGANISATION.ALL, page, size, filter],
    async () => {
      return await getPage<Organisation>(`${API_PATH.ORGANISATIONS}/page`, page, size, filter);
    },
    options,
  );
};

export const useQueryOrganisation = (id: string, options?: TanstackQueryOptions<Organisation>) => {
  const { sendGetRequest } = useApi();

  return useQuery<Organisation, Error>(
    [QUERY_KEYS.ORGANISATION.ONE, id],
    async () => sendGetRequest<Organisation>(`${API_PATH.ORGANISATIONS}/${id}`),
    options,
  );
};

export const useCreateOrganisation = () => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (organisation: Organisation) =>
      await sendRequestWithBody('POST', API_PATH.ORGANISATIONS, organisation),
    {
      onSuccess: (_, organisation) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANISATION.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANISATION.ONE, organisation.id] });
      },
    },
  );
};

export const useModifyOrganisation = (id: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (organisation: Organisation) => {
      await sendRequestWithBody('PUT', `${API_PATH.ORGANISATIONS}/${id}`, organisation);
    },
    {
      onSuccess: (_, organisation) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANISATION.ONE, organisation.id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANISATION.ALL] });
      },
    },
  );
};

export const useDeleteOrganisation = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => await sendDeleteRequest(`${API_PATH.ORGANISATIONS}/${id}`),
    {
      onSuccess: (id: string) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANISATION.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.ORGANISATION.ONE, id] });
      },
    },
  );
};
