import { useCallback, createContext, ReactNode, useState } from 'react';
import { CustomModalProps, ModalContextType } from 'models/modal.model';

const noModalProviderError = Error('You need to use getContext inside an ModalContext.Provider');

export const ModalContext = createContext<ModalContextType>({
  modals: [],
  openModal: () => {
    throw noModalProviderError;
  },
});

const ModalProvider = ({ children }: { children?: ReactNode }) => {
  const [modals, setModals] = useState<CustomModalProps[]>([]);

  const popModal = useCallback(() => {
    setModals((modals) => modals.slice(1));
  }, [setModals]);

  const openModal = useCallback(
    (props: CustomModalProps) => {
      setModals((modals) => [
        ...modals,
        {
          ...props,
          onClose: () => {
            if (props.onClose) {
              props.onClose();
            }
            popModal();
          },
        },
      ]);
    },
    [setModals, modals],
  );

  return <ModalContext.Provider value={{ modals, openModal }}>{children}</ModalContext.Provider>;
};
export default ModalProvider;
