import { useQuery } from '@tanstack/react-query';
import useApi from './useApi';
import { CountryDto } from 'models/country.model';
import { API_PATH } from './paths';
import { QUERY_KEYS } from './queryKeys';

export const useQueryCountries = () => {
  const { sendGetRequest } = useApi();
  return useQuery<CountryDto[], Error>([QUERY_KEYS.COUNTRY.ALL], async () => {
    return await sendGetRequest<CountryDto[]>(API_PATH.COUNTRIES);
  });
};
