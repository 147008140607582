import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useApi from 'api/useApi';
import { API_PATH } from 'api/paths';
import { WaitingRoom, WaitingRoomAddPatient } from 'models/waitingRoom.model';
import { QUERY_KEYS } from './queryKeys';
import { TanstackQueryOptions } from 'models/api.model';

export const useAddWaitingRoom = () => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (waiting: WaitingRoomAddPatient) =>
      await sendRequestWithBody('POST', API_PATH.WAITING_ROOMS, waiting),
    {
      onSuccess: (_, waitingRoomAddDto) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.WAITING_ROOM.ONE, waitingRoomAddDto.patientId],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PATIENT.ONE, waitingRoomAddDto.patientId],
        });
      },
    },
  );
};

export const useQueryWaitingRoom = (
  waitingRoomId: string,
  options?: TanstackQueryOptions<WaitingRoom>,
) => {
  const { sendGetRequest } = useApi();
  return useQuery<WaitingRoom, Error>(
    [QUERY_KEYS.WAITING_ROOM.ONE, waitingRoomId],
    async () => (await sendGetRequest(`${API_PATH.WAITING_ROOMS}/${waitingRoomId}`)) as WaitingRoom,
    options,
  );
};

export const useQueryWaitingRooms = (centerId: string, timestamp: number) => {
  const { sendGetRequest } = useApi();
  return useQuery<WaitingRoom[], Error>(
    [QUERY_KEYS.WAITING_ROOM.ALL, centerId, timestamp],
    async () =>
      (await sendGetRequest(
        `${API_PATH.WAITING_ROOMS}?centerId=${centerId}&timestamp=${timestamp}`,
      )) as WaitingRoom[],
  );
};

export const useDeleteWaitingRoom = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (id: string) => await sendDeleteRequest(`${API_PATH.WAITING_ROOMS}/${id}`),
    {
      onSuccess: (WaitingRoom: WaitingRoom) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.PATIENT.ONE, WaitingRoom.patient.id],
        });
      },
    },
  );
};

export const useChangeStatusWaitingRoom = (id: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (waitingRoom: WaitingRoom) => {
      return await sendRequestWithBody('PUT', `${API_PATH.WAITING_ROOMS}/${id}`, waitingRoom);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE, id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ALL] });
      },
    },
  );
};
