import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useApi from './useApi';
import { API_PATH } from './paths';
import { Consultation } from 'models/consultation.model';
import { QUERY_KEYS } from './queryKeys';
import { TanstackQueryOptions } from 'models/api.model';

export const useQueryConsultation = (id: string, options?: TanstackQueryOptions<Consultation>) => {
  const { sendGetRequest } = useApi();

  return useQuery<Consultation, Error>(
    [QUERY_KEYS.CONSULTATION.ONE, id],
    async () => sendGetRequest<Consultation>(`${API_PATH.CONSULTATIONS}/${id}`),
    options,
  );
};

export const useQueryConsultations = (waitingRoomId: string) => {
  const { sendGetRequest } = useApi();
  return useQuery<Consultation[], Error>(
    [QUERY_KEYS.CONSULTATION.ALL, waitingRoomId],
    () =>
      sendGetRequest(`${API_PATH.CONSULTATIONS}?waitingroom=${waitingRoomId}`) as Promise<
        Consultation[]
      >,
  );
};

export const useModifyConsultation = (id: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (consultation: Consultation) =>
      sendRequestWithBody('PUT', `${API_PATH.CONSULTATIONS}/${id}`, consultation),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ONE, id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
      },
    },
  );
};

export const useDeleteConsultation = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation((id: string) => sendDeleteRequest(`${API_PATH.CONSULTATIONS}/${id}`), {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ALL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ONE, id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
    },
  });
};

export const useCancelWaitingStatus = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation((id: string) => sendDeleteRequest(`${API_PATH.CONSULTATIONS}/status/${id}`), {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ALL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CONSULTATION.ONE, id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ONE] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.WAITING_ROOM.ALL] });
    },
  });
};
