import { Box, CircularProgress } from '@mui/material';

const Loader = () => (
  <Box
    sx={{
      height: '100svh',
      width: '100svw',
      backgroundColor: 'background.default',
      display: 'flex',
    }}
  >
    <CircularProgress disableShrink size={240} sx={{ margin: 'auto' }} />
  </Box>
);
export default Loader;
