import { Box } from '@mui/material';
import AdministrationCard from 'components/administrationPanel/AdministrationCard';
import AdministrationNavigation from 'components/administrationPanel/AdministrationNavigation';
import { ReactComponent as CenterIcon } from 'assets/centers.svg';
import { ReactComponent as OrganisationIcon } from 'assets/organisations.svg';
import { ReactComponent as UserIcon } from 'assets/users.svg';
import { ROUTE } from 'router/routes';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { Roles } from 'models/role.model';
import { AdministrationCardProps } from 'models/administration.model';

const AdminPage = () => {
  const { t } = useTranslation();
  const { roles } = useContext(AuthenticationContext);
  const navigate = useNavigate();

  const links: AdministrationCardProps[] = [
    {
      to: ROUTE.ADMINISTRATION.USERS.INDEX,
      icon: UserIcon,
      viewBox: '0 0 693 597',
      label: t('administrationPanel.benevoles'),
    },
    {
      to: ROUTE.ADMINISTRATION.CENTERS.INDEX,
      icon: CenterIcon,
      viewBox: '0 0 582 693',
      label: t('administrationPanel.centers'),
    },
    {
      to: ROUTE.ADMINISTRATION.ORGANISATIONS.INDEX,
      icon: OrganisationIcon,
      viewBox: '0 0 1105 783',
      label: t('administrationPanel.organisations'),
    },
  ];

  useEffect(() => {
    if (!roles.includes(Roles.SuperAdministrator)) {
      navigate(ROUTE.ADMINISTRATION.INDEX);
    }
  }, []);

  return (
    <Box
      component="main"
      sx={{
        p: '0 24px 32px 24px',
        m: 0,
        height: 'calc(100% - 64px)',
        width: '100vw',
      }}
    >
      <AdministrationNavigation />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          marginTop: '3vh',
          height: '100%',
        }}
      >
        {links.map((link, index) => (
          <AdministrationCard {...link} key={`admin-card-${index}`} />
        ))}
      </Box>
    </Box>
  );
};
export default AdminPage;
