import { SxProps } from '@mui/material';
import { PatientWaitingDto } from './patient.model';

export enum WAITING_ROOM_COLOR {
  Waiting = '#5076FF',
  ConsultingSocialWorker = '#FD60D1',
  ConsultingDoctor = '#F39238',
  ConsultingOther = '#F0E261',
  Refused = '#FF0000',
  EndConsultation = '#00FF00',
}

export enum ChangingStatus {
  Waiting = 'Waiting',
  ConsultingSocialWorker = 'ConsultingSocialWorker',
  ConsultingDoctor = 'ConsultingDoctor',
  ConsultingOther = 'ConsultingOther',
}

export enum WaitingRoomStatus {
  Waiting = 'Waiting',
  ConsultingSocialWorker = 'ConsultingSocialWorker',
  ConsultingDoctor = 'ConsultingDoctor',
  ConsultingOther = 'ConsultingOther',
  Refused = 'Refused',
  EndConsultation = 'EndConsultation',
}

export type WaitingRoom = {
  id: string;
  createdDate: Date;
  updatedDate: Date;
  status: WaitingRoomStatus;
  remark: string;
  patient: PatientWaitingDto;
};

export type WaitingRoomAddPatient = {
  patientId: string;
  centerId: string;
};

export type WaitingRoomCardProps = {
  status: WaitingRoomStatus;
  firstname?: string;
  lastname?: string;
  onClick: () => void;
};

export type WaitingRoomListProps = {
  sx?: SxProps;
  onClick: (waitingroomId: string) => void;
  isListHistoricized?: boolean;
};
