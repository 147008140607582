import { createTheme } from '@mui/material';
import * as locales from '@mui/material/locale';
import * as dataGrids from '@mui/x-data-grid';
import * as datePickers from '@mui/x-date-pickers';
import i18next from 'i18next';
import { MuiLocalisationCode } from 'models/language.model';
import '@fontsource-variable/nunito';
import '@fontsource-variable/nunito-sans';

const fontFamily = "'Nunito Sans Variable', sans-serif";

const baseTheme = {
  palette: {
    primary: {
      main: '#015DAB',
    },
    background: {
      paper: '#FFFFFF',
      default: '#F8F9FF',
    },
    action: {
      hover: '#015DAB',
    },
    gray1: {
      main: '#333333',
    },
    darkText: {
      main: '#2E3033',
    },
    link: {
      main: '#005CAA',
    },
  },
};

const GetTheme = () => {
  let theme = createTheme(baseTheme);

  const buttonStyle = {
    boxShadow: 'none',
    fontFamily,
    textTransform: 'none',
  };

  const buttonOutlinedStyle = {
    'backgroundColor': theme.palette.background.paper,
    'color': theme.palette.primary.main,
    'borderColor': theme.palette.primary.main,
    'borderWidth': '2px',
    ':hover': {
      borderWidth: '2px',
    },
  };

  const buttonTextStyle = {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  };

  const buttonContainedStyle = {
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.background.paper,
    ':hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  };

  theme = createTheme(theme, {
    typography: {
      fontFamily,
      fontSize: 14,
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: () => ({
          body: {
            'color': baseTheme.palette.darkText.main,
            /* ===== Scrollbar CSS ===== */
            /* Firefox */
            '*': {
              scrollbarWidth: 'thin',
              scrollbarColor: `${theme.palette.primary.main} #d9d9d9`,
            },

            /* Chrome, Edge, and Safari */
            '*::-webkit-scrollbar': {
              width: '8px',
            },
            '*::-webkit-scrollbar-track': {
              background: '#d9d9d9',
              borderRadius: '10px',
            },
            '*::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: '10px',
              border: 'none',
            },
            '.MuiMenu-paper': { borderRadius: '15px', padding: 0 },
          },
        }),
      },
      MuiAlert: {
        variants: [
          {
            props: {},
            style: {
              fontSize: '1.1rem',
              fontFamily,
            },
          },
        ],
      },
      MuiIconButton: {
        variants: [
          {
            props: {},
            style: {
              ...buttonStyle,
              'backgroundColor': 'transparent',
              ':hover': { backgroundColor: '#DFECF7' },
              ':active': { backgroundColor: '#E2EDFE' },
            },
          },
        ],
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'text' },
            style: { ...buttonStyle, ...buttonTextStyle },
          },
          {
            props: { variant: 'outlined' },
            style: { ...buttonStyle, ...buttonOutlinedStyle },
          },
          {
            props: { variant: 'contained' },
            style: { ...buttonStyle, ...buttonContainedStyle },
          },
        ],
      },
      MuiTypography: {
        variants: [
          {
            props: {},
            style: {
              fontFamily,
              fontSize: '14px',
            },
          },
        ],
      },
      MuiInputBase: {
        variants: [
          {
            props: {},
            style: {
              fontFamily,
              fontSize: '14px',
            },
          },
        ],
      },
      MuiFormLabel: {
        variants: [
          {
            props: {},
            style: {
              fontFamily,
              'fontSize': '14px',
              '& span.MuiFormLabel-asterisk': {
                color: theme.palette.error.main,
              },
            },
          },
        ],
      },
      MuiList: {
        variants: [
          {
            props: {},
            style: {
              padding: '15px 0',
            },
          },
        ],
      },
    },
  });

  const muiLocalisationCode = i18next.t('i18n.muiCode') as MuiLocalisationCode;
  //See https://mui.com/material-ui/guides/localization/ for an exhaustive list of translation supported by MUI.
  return createTheme(
    theme,
    locales[muiLocalisationCode], // core translations
    dataGrids[muiLocalisationCode], // x-data-grid translations
    datePickers[muiLocalisationCode], // x-date-pickers translations
  );
};
export default GetTheme;
