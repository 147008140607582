import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useContext, useEffect } from 'react';
import { NotificationContext } from 'components/NotificationProvider';
import { useFormProps } from 'models/form.models';
import { zodResolver } from '@hookform/resolvers/zod';
import useDynamicTranslation from 'hooks/useDynamicTranslation';

const useCustomForm = <T extends object>({
  isEdition,
  isOpen,
  data,
  schema,
  mutate,
}: useFormProps<T>) => {
  const { sendNotification } = useContext(NotificationContext);
  const { i18n, t } = useTranslation();
  const dynamicTranslation = useDynamicTranslation();
  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<T>({
    resolver: zodResolver(schema),
  });

  const handleError = useCallback(
    (e: Error) => {
      const errorMessage = dynamicTranslation(`error.${e.message}`);
      if (errorMessage) {
        sendNotification(`${errorMessage} (${e.message})`, 'error');
      } else {
        sendNotification(t(`error.default`), 'error');
      }
    },
    [i18n.exists, t, sendNotification],
  );

  const customHandleSubmit = useCallback(
    (onSaved: () => void) =>
      handleSubmit(async (data) => {
        try {
          await mutate(data, { onSuccess: onSaved });
        } catch (e) {
          handleError(e as Error);
        }
      }),
    [handleSubmit, handleError, mutate, isEdition],
  );

  useEffect(() => {
    reset(data);
  }, [data, isOpen]);

  return {
    control,
    setValue,
    getValues,
    register,
    handleSubmit: customHandleSubmit,
    errors,
    isDirty,
  };
};
export default useCustomForm;
