import { Alert, List, ListItem } from '@mui/material';
import { NotificationContext } from 'components/NotificationProvider';
import { useContext } from 'react';

const NotificationStack = () => {
  const { notifications, removeNotification } = useContext(NotificationContext);
  return (
    <List
      sx={{
        position: 'absolute',
        top: '20px',
        left: '50%',
        transform: 'translate(-50%, 0)',
        zIndex: '4200',
        heigth: '100%',
        bgcolor: 'transparent',
      }}
    >
      {notifications.map((notification) => (
        <ListItem key={notification.id}>
          <Alert
            severity={notification.severity}
            onClick={() => removeNotification(notification.id)}
          >
            {notification.message}
          </Alert>
        </ListItem>
      ))}
    </List>
  );
};
export default NotificationStack;
