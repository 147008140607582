import { CityDto } from 'models/city.model';
import useApi from './useApi';
import { useQuery } from '@tanstack/react-query';
import { API_PATH } from './paths';
import { QUERY_KEYS } from './queryKeys';

export const useQueryCities = () => {
  const { sendGetRequest } = useApi();
  return useQuery<CityDto[], Error>([QUERY_KEYS.CITY.ALL], async () => {
    return await sendGetRequest<CityDto[]>(API_PATH.CITIES);
  });
};
