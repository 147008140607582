import { ModalContext } from 'components/ModalProvider';
import { useContext } from 'react';
import CustomModal from './CustomModal';

const CurrentModal = () => {
  const { modals } = useContext(ModalContext);

  if (modals.length > 0) {
    return <CustomModal {...modals[0]} />;
  }
  return <></>;
};
export default CurrentModal;
