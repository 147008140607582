import { MenuItem, Select, Typography } from '@mui/material';
import { useQueryCenters } from 'api';
import { useContext, useEffect, useMemo } from 'react';
import { AuthenticationContext } from './AuthenticationProvider';

const CenterPicker = () => {
  const { data: centers, isLoading } = useQueryCenters();
  const { centerId, changeCenter } = useContext(AuthenticationContext);
  const currentCenter = useMemo(
    () => centers?.find((center) => center.id === centerId),
    [centers, centerId],
  );

  useEffect(() => {
    if (!isLoading && !currentCenter && centers && centers[0]?.id) {
      changeCenter(centers[0].id);
    }
  }, [centers]);

  if (isLoading) {
    return <></>;
  }
  return (
    <Select
      sx={{
        'flex': 'none',
        'order': '1',
        'flexGrow': '0',
        'height': '64px',
        'boxShadow': 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '& .MuiSvgIcon-root': { color: '#FFF' },
      }}
      value={centerId}
      renderValue={() => (
        <Typography sx={{ alignSelf: 'center', fontSize: '20px', color: '#FFF' }}>
          {currentCenter?.name}
        </Typography>
      )}
    >
      {centers &&
        centers.map((center) => (
          <MenuItem key={center.id} value={center.id} onClick={() => changeCenter(center.id)}>
            <Typography sx={{ alignSelf: 'center', fontSize: '20px' }}>{center.name}</Typography>
          </MenuItem>
        ))}
    </Select>
  );
};
export default CenterPicker;
