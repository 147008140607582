import { z } from 'zod';
import useAddressValidator from 'hooks/useAddressValidator';
import { useTranslation } from 'react-i18next';

const useOrganisationValidator = () => {
  const { t } = useTranslation();
  const addressValidator = useAddressValidator();
  const organisationValidator = z.object({
    id: z.string(),
    name: z.string().min(1, { message: t('form.required') }),
    addressDto: addressValidator,
  });
  return organisationValidator;
};
export default useOrganisationValidator;
