import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import WaitingRoomCard from './WaitingRoomCard';
import { useQueryWaitingRooms } from 'api';
import { useContext, useState } from 'react';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { WaitingRoomListProps, WaitingRoomStatus } from 'models/waitingRoom.model';
import { DatePicker } from '@mui/x-date-pickers';

const WaitingRoomList = ({ sx, onClick, isListHistoricized = false }: WaitingRoomListProps) => {
  const { t } = useTranslation();
  const [timestamp, setTimestamp] = useState(Date.now());
  const { centerId } = useContext(AuthenticationContext);
  const { data } = useQueryWaitingRooms(centerId, timestamp);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '16px',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Box
        sx={{
          'backgroundColor': 'primary.main',
          'boxShadow': '0px 4px 10px rgba(0, 0, 0, 0.12)',
          'borderRadius': '15px',
          'padding': '16px',
          'gap': '8px',
          'display': 'grid',
          'gridTemplateColumns': '2fr 2fr',
          'gridTemplateRows': 'repeat(3, auto)',
          '& > *': {
            color: 'background.paper',
            fontSize: '16px',
            lineHeight: '140%',
          },
        }}
      >
        <Typography sx={{ fontSize: '24px', gridRow: '1', gridColumn: '1 / 3' }}>
          {t('waitingroom.title')}
        </Typography>
        {isListHistoricized && (
          <Box
            sx={{
              gridRow: '2',
              gridColumn: '1/3',
              w: '100%',
            }}
          >
            <Typography sx={{ fontSize: '16px' }}>{t('waitingroom.date')}</Typography>
            <DatePicker
              sx={{
                'backgroundColor': 'background.paper',
                'borderRadius': '5px',
                'width': '100%',
                '& .MuiIconButton-root': {
                  backgroundColor: 'background.paper',
                  color: 'gray1.main',
                },
              }}
              value={timestamp}
              onChange={(value) => setTimestamp(value ? value.valueOf() : Date.now())}
            />
          </Box>
        )}
        <Typography sx={{ fontSize: '16px', gridRow: '3', gridColumn: '1' }}>
          {t('waitingroom.patientNumber')}
        </Typography>
        <Typography sx={{ fontSize: '16px', gridRow: '3', gridColumn: '2' }}>
          {data?.length ?? 0}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          overflowY: 'auto',
          overflowX: 'hidden',
          paddingRight: '8px',
          paddingBottom: '8px',
        }}
      >
        {data?.map((waitingroom) => (
          <WaitingRoomCard
            key={waitingroom.id}
            status={waitingroom.status as WaitingRoomStatus}
            firstname={waitingroom.patient.firstname}
            lastname={waitingroom.patient.lastname}
            onClick={() => onClick(waitingroom.id)}
          />
        ))}
      </Box>
    </Box>
  );
};
export default WaitingRoomList;
