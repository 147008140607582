import { useCallback, createContext, ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Notification,
  NotificationContextType,
  NotificationSeverity,
} from 'models/notification.model';

const defaultNotificationDuration = 6000; //Remove the notification after this time in milliseconds

const noNotificationProviderError = Error(
  'You need to use getContext inside an NotificationContext.Provider',
);

export const NotificationContext = createContext<NotificationContextType>({
  notifications: [],
  sendNotification: () => {
    throw noNotificationProviderError;
  },
  removeNotification: () => {
    throw noNotificationProviderError;
  },
});

const NotificationProvider = ({ children }: { children?: ReactNode }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const sendNotification = useCallback(
    (
      message: string,
      severity: NotificationSeverity,
      duration: number = defaultNotificationDuration,
    ) => {
      const id = uuidv4();
      setNotifications((notifications) => [
        ...notifications,
        {
          id,
          message,
          severity,
          timeout: setTimeout(() => {
            setNotifications((notifications) =>
              notifications.filter((notification) => notification.id !== id),
            );
          }, duration),
        },
      ]);
    },
    [notifications, setNotifications],
  );

  const removeNotification = useCallback(
    (id: string) => {
      clearTimeout(notifications.find((notification) => notification.id === id)?.timeout);
      setNotifications((notifications) =>
        notifications.filter((notification) => notification.id !== id),
      );
    },
    [notifications, setNotifications],
  );

  return (
    <NotificationContext.Provider value={{ notifications, sendNotification, removeNotification }}>
      {children}
    </NotificationContext.Provider>
  );
};
export default NotificationProvider;
