import useCustomForm from 'hooks/useCustomForm';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContext } from 'components/NotificationProvider';
import { CustomFormModalProps } from 'models/modal.model';
import { CustomField } from 'models/form.models';
import { User } from 'models/user.model';
import useUserValidator from 'validators/useUserValidator';
import {
  useQueryJobs,
  useQueryRoles,
  useQueryOrganisations,
  useCreateUser,
  useModifyUser,
  useQueryUser,
} from 'api';
import useDynamicTranslation from 'hooks/useDynamicTranslation';
import FormModal from 'components/modals/FormModal';

const emptyUser: User = {
  id: '',
  ssn: '',
  organisationId: null,
  firstname: null,
  lastname: null,
  status: false,
  jobId: null,
  rolesIds: [],
};

const UserModal = ({ id, isEdition, isOpen, onClose }: CustomFormModalProps<User>) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const dynamicTranslation = useDynamicTranslation();
  const { isLoading: areJobsLoading, data: jobs } = useQueryJobs();
  const { isLoading: areRolesLoading, data: roles } = useQueryRoles();
  const { isLoading: areOrganisationsLoading, data: organisations } = useQueryOrganisations();
  const { isLoading: isUserLoading, data: user } = useQueryUser(id, { enabled: isEdition });
  const { mutateAsync: createUser } = useCreateUser();
  const { mutateAsync: modifyUser } = useModifyUser(id);
  const isLoading = useMemo(
    () =>
      (isEdition && isUserLoading) || areJobsLoading || areRolesLoading || areOrganisationsLoading,
    [isUserLoading, areJobsLoading, areRolesLoading, areOrganisationsLoading],
  );
  const data = useMemo(() => (isEdition && user ? user : emptyUser), [isEdition, user]);
  const cancelModalTitle = useMemo(
    () => t(isEdition ? 'modals.userForm.title.edit' : 'modals.userForm.title.add'),
    [t],
  );
  const getRoleLabel = useCallback(
    (roleId: string) => {
      const role = roles?.find((role) => role.id === roleId);
      if (!role) {
        return '';
      }
      return (
        dynamicTranslation(`role.${role.name[0].toLowerCase() + role.name.slice(1)}`) ?? role.name
      );
    },
    [roles, dynamicTranslation],
  );

  const userValidator = useUserValidator();
  const { control, handleSubmit, isDirty, register, errors } = useCustomForm<User>({
    isEdition,
    isOpen,
    data: data,
    schema: userValidator,
    mutate: isEdition ? modifyUser : createUser,
  });

  const fields: CustomField[] = [
    {
      type: 'text',
      hasError: Boolean(errors.lastname),
      gridRow: 1,
      gridColumn: 1,
      helperText: errors.lastname?.message,
      label: t('user.lastname'),
      register: register('lastname'),
      isRequired: !userValidator.shape.lastname.isNullable(),
      maxLength: 100,
    },
    {
      type: 'text',
      hasError: Boolean(errors.firstname),
      gridRow: 1,
      gridColumn: 2,
      helperText: errors.firstname?.message,
      label: t('user.firstname'),
      register: register('firstname'),
      isRequired: !userValidator.shape.firstname.isNullable(),
      maxLength: 20,
    },
    {
      type: 'text',
      hasError: Boolean(errors.ssn),
      gridRow: 2,
      gridColumn: 1,
      helperText: errors.ssn?.message,
      label: t('user.ssn'),
      register: register('ssn'),
      isRequired: !userValidator.shape.ssn.isNullable(),
      maxLength: 20,
    },
    {
      type: 'multiselect',
      hasError: Boolean(errors.rolesIds),
      gridRow: 2,
      gridColumn: 2,
      helperText: errors.rolesIds?.message,
      label: t('user.roles'),
      register: register('rolesIds'),
      isRequired: !userValidator.shape.rolesIds.isNullable(),
      options:
        roles?.map((role) => ({
          value: role.id,
          label: getRoleLabel(role.id),
        })) ?? [],
    },
    {
      type: 'select',
      hasError: Boolean(errors.jobId),
      gridRow: 3,
      gridColumn: 1,
      helperText: errors.jobId?.message,
      label: t('user.job'),
      register: register('jobId'),
      isRequired: !userValidator.shape.jobId.isNullable(),
      options:
        jobs?.map((job) => ({
          value: job.id,
          label: dynamicTranslation(job.name) ?? job.name,
        })) ?? [],
    },
    {
      type: 'select',
      hasError: Boolean(errors.organisationId),
      gridRow: 3,
      gridColumn: 2,
      helperText: errors.organisationId?.message,
      label: t('user.organisation'),
      register: register('organisationId'),
      options: [
        { value: '', label: t('user.none') },
        ...(organisations?.map((organisation) => ({
          value: organisation.id,
          label: dynamicTranslation(organisation.name) ?? organisation.name,
        })) ?? []),
      ],
    },
  ];

  const onSaved = useCallback(() => {
    sendNotification(
      t(isEdition ? 'notifications.userTable.modify' : 'notifications.userTable.add'),
      'success',
    );
    onClose();
  }, [onClose, sendNotification, t]);

  return (
    <FormModal<User>
      title={isEdition ? t('user.edit') : t('user.add')}
      control={control}
      isEdition={isEdition}
      isOpen={isOpen}
      onValid={handleSubmit(onSaved)}
      isDirty={isDirty}
      fields={fields}
      handleClose={onClose}
      isLoading={isEdition && isLoading}
      cancelModalTitle={cancelModalTitle}
    />
  );
};
export default UserModal;
