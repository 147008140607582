import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE } from 'router/routes';

const AdministrationNavigation = ({ currentPage }: { currentPage?: string }) => {
  const { t } = useTranslation();
  return (
    <Breadcrumbs sx={{ margin: '8px 0' }}>
      <Link
        underline="hover"
        color="inherit"
        component={RouterLink}
        to={ROUTE.ADMINISTRATION.INDEX}
        sx={{ fontSize: '1.5em' }}
      >
        {t('administrationPanel.administration')}
      </Link>
      {currentPage && <Typography sx={{ fontSize: '1.5em' }}>{currentPage}</Typography>}
    </Breadcrumbs>
  );
};
export default AdministrationNavigation;
