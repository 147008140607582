import { Navigate, Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import NavBar from 'components/navbar/NavBar';
import NotificationStack from './NotificationStack';
import { useContext } from 'react';
import { AuthenticationContext } from 'components/AuthenticationProvider';
import { ROUTE } from 'router/routes';
import CurrentModal from 'components/modals/CurrentModal';
import Loader from './Loader';

const Layout = () => {
  const { isLoading, isLogged } = useContext(AuthenticationContext);

  if (!isLoading && !isLogged) {
    return <Navigate to={ROUTE.LOGIN} />;
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        background: 'background.default',
        overflow: 'hidden',
      }}
    >
      <CurrentModal />
      <NotificationStack />
      <NavBar />
      <Outlet />
    </Box>
  );
};
export default Layout;
