import { IconButton, Tooltip } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridTreeNodeWithRender,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useDeleteOrganisation, useQueryOrganisationsPage } from 'api';
import { ModalContext } from 'components/ModalProvider';
import { NotificationContext } from 'components/NotificationProvider';
import { TableState } from 'models/dataGridCustom.model';
import { Organisation } from 'models/organisation.model';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DataGridCustom from 'components/dataGrid/DataGridCustom';

const OrganisationTable = ({
  filter,
  editOrganisation,
}: {
  filter: string;
  editOrganisation: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const { sendNotification } = useContext(NotificationContext);
  const { openModal } = useContext(ModalContext);
  const { mutateAsync: deleteOrganisation } = useDeleteOrganisation();
  const [pageState, setPageState] = useState<TableState>({
    page: 0,
    pageSize: 0,
  });

  // Set cache time to 0 to disable request caching
  // since there's a bug with MUI data grid when loading the page with cached data
  const { isLoading, data } = useQueryOrganisationsPage(
    pageState.page,
    pageState.pageSize,
    filter,
    { cacheTime: 0 },
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('organisation.name'),
      sortable: false,
      flex: 1,
    },
    {
      field: 'address.street',
      headerName: t('organisation.street'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Organisation, GridTreeNodeWithRender>,
      ) => params.row.addressDto.street,
    },
    {
      field: 'address.complement',
      headerName: t('organisation.complement'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Organisation, GridTreeNodeWithRender>,
      ) => params.row.addressDto.complement,
    },
    {
      field: 'address.zipCode',
      headerName: t('organisation.zipCode'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Organisation, GridTreeNodeWithRender>,
      ) => params.row.addressDto.zipCode,
    },
    {
      field: 'address.city.name',
      headerName: t('organisation.city'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Organisation, GridTreeNodeWithRender>,
      ) => params.row.addressDto.cityDto.name,
    },
    {
      field: 'address.city.country',
      headerName: t('organisation.country'),
      sortable: false,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Organisation, GridTreeNodeWithRender>,
      ) => params.row.addressDto.cityDto.countryDto.name,
    },
    {
      field: 'action',
      width: 100,
      headerName: t('action.actions').toString(),
      sortable: false,
      renderCell: (
        params: GridRenderCellParams<GridValidRowModel, Organisation, GridTreeNodeWithRender>,
      ) => {
        return (
          <>
            <Tooltip title={t('action.edit')} arrow>
              <IconButton
                onClick={() => {
                  editOrganisation(params.id.toString());
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t('action.delete')} arrow>
              <IconButton
                onClick={() => {
                  openModal({
                    title: t('modals.deleteOrganisation.title', {
                      name: params.row.name,
                    }).toString(),
                    message: t('modals.deleteOrganisation.message'),
                    cancelButton: {
                      label: t('action.cancel').toString(),
                      onClick: () => {
                        return;
                      },
                    },
                    validButton: {
                      label: t('action.delete').toString(),
                      onClick: async () => {
                        await deleteOrganisation(params.id.toString());
                        sendNotification(t('notifications.organisationTable.delete'), 'success');
                      },
                    },
                    onClose: () => {
                      return;
                    },
                  });
                }}
              >
                <DeleteForeverIcon color="primary" />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => setPageState({ ...pageState, page: 0 }), [filter]);

  return (
    <DataGridCustom
      isPaginated={true}
      data={data}
      isLoading={isLoading}
      state={pageState}
      columns={columns}
      getRowId={(row) => row.id}
      onPaginationModelChange={(newPageModel) => {
        setPageState({
          page: newPageModel.page,
          pageSize: newPageModel.pageSize,
        });
      }}
    />
  );
};

export default OrganisationTable;
