import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Center } from 'models/center.model';
import useApi from 'api/useApi';
import { API_PATH } from 'api/paths';
import { QUERY_KEYS } from './queryKeys';
import { Page, TanstackQueryOptions } from 'models/api.model';

export const useQueryCenter = (id?: string, options?: TanstackQueryOptions<Center>) => {
  const { sendGetRequest } = useApi();

  return useQuery<Center, Error>(
    [QUERY_KEYS.CENTER.ONE, id],
    async () => sendGetRequest<Center>(`${API_PATH.CENTERS}/${id}`),
    options,
  );
};

export const useQueryCenters = () => {
  const { sendGetRequest } = useApi();
  return useQuery<Center[], Error>([QUERY_KEYS.CENTER.ALL], async () =>
    sendGetRequest<Center[]>(API_PATH.CENTERS),
  );
};

export const useQueryCentersPage = (
  page: number,
  size: number,
  filter: string,
  options?: TanstackQueryOptions<Page<Center>>,
) => {
  const { getPage } = useApi();
  return useQuery<Page<Center>, Error>(
    [QUERY_KEYS.CENTER.PAGE, page, size, filter],
    async () => getPage<Center>(`${API_PATH.CENTERS}/page`, page, size, filter),
    options,
  );
};

export const useCreateCenter = () => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (center: Center) => await sendRequestWithBody('POST', API_PATH.CENTERS, center),
    {
      onSuccess: (_, center) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.PAGE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.ONE, center.id] });
      },
    },
  );
};

export const useModifyCenter = (id?: string) => {
  const { sendRequestWithBody } = useApi();
  const queryClient = useQueryClient();
  return useMutation(
    async (center: Center) => {
      await sendRequestWithBody('PUT', `${API_PATH.CENTERS}/${id}`, center);
    },
    {
      onSuccess: (_, center) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.ALL] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.PAGE] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.ONE, center.id] });
      },
    },
  );
};

export const useDeleteCenter = () => {
  const { sendDeleteRequest } = useApi();
  const queryClient = useQueryClient();

  return useMutation(async (id: string) => await sendDeleteRequest(`${API_PATH.CENTERS}/${id}`), {
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.ALL] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.PAGE] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.CENTER.ONE, id] });
    },
  });
};
